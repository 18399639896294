var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.breakout.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';var Bra=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.wB);return $CLJS.Re(function(c){return $CLJS.xC(b,c)},$CLJS.qF)},H7=new $CLJS.M("metabase.lib.order-by","order-by-clause","metabase.lib.order-by/order-by-clause",774979647);$CLJS.wF($CLJS.lG,H7);$CLJS.wF($CLJS.eG,H7);
$CLJS.K0.m(null,$CLJS.UR,function(a,b){var c=$CLJS.Fe($CLJS.UR.h($CLJS.LW(a,b)));return $CLJS.n(c)?$CLJS.oE("Sorted by {0}",$CLJS.H([$CLJS.i2($CLJS.QE("and"),function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.h0.v(a,b,v,$CLJS.i0);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.A(k);
return $CLJS.ee($CLJS.h0.v(a,b,t,$CLJS.i0),f($CLJS.Lc(k)))}return null}},null,null)}(c)}())])):null});$CLJS.H0.m(null,H7,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.h0.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "asc":return $CLJS.oE("{0} ascending",$CLJS.H([a]));case "desc":return $CLJS.oE("{0} descending",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});
$CLJS.O0.m(null,H7,function(a,b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.R.j($CLJS.k0.j(a,b,c),$CLJS.v0,d)});var I7,Cra=$CLJS.Xe($CLJS.N),Dra=$CLJS.Xe($CLJS.N),Era=$CLJS.Xe($CLJS.N),Fra=$CLJS.Xe($CLJS.N),Gra=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.kj,$CLJS.lC],null),$CLJS.kj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));I7=new $CLJS.di($CLJS.Fh.g("metabase.lib.order-by","order-by-clause-method"),$CLJS.jC,Gra,Cra,Dra,Era,Fra);I7.m(null,H7,function(a){return $CLJS.vW(a)});
I7.m(null,$CLJS.ci,function(a){if(null==a)throw $CLJS.hi($CLJS.QE("Can''t order by nil"),$CLJS.N);return $CLJS.vW(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lG,$CLJS.wW(a)],null))});
$CLJS.J7=function(){function a(d,e){d=I7.h(d);e=$CLJS.n(e)?e:$CLJS.lG;return $CLJS.R.j($CLJS.Df(d),0,e)}function b(d){return c.g?c.g(d,$CLJS.lG):c.call(null,d,$CLJS.lG)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.K7=function(){function a(e,f,k,l){f=$CLJS.n(f)?f:-1;var m=function(){var t=I7.h(k);return $CLJS.n(l)?$CLJS.R.j($CLJS.Df(t),0,l):t}();return $CLJS.WW.l(e,f,$CLJS.Wk,$CLJS.H([$CLJS.UR,function(t){return $CLJS.be.g($CLJS.Df(t),m)}]))}function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.v?d.v(e,-1,f,null):d.call(null,e,-1,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,
e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();$CLJS.L7=function(){function a(d,e){return $CLJS.Fe($CLJS.J.g($CLJS.LW(d,e),$CLJS.UR))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.M7=function(){function a(d,e){var f=$CLJS.Fe($CLJS.V5.g(d,e)),k=$CLJS.Fe($CLJS.H4.g(d,e));f=$CLJS.n($CLJS.n(f)?f:k)?$CLJS.gf.g(f,k):function(){var t=$CLJS.LW(d,e);return $CLJS.S0.v(d,e,t,new $CLJS.h(null,1,[$CLJS.E0,!1],null))}();var l=$CLJS.lf(Bra,f);f=$CLJS.hf.g(function(t){$CLJS.I(t,0,null);$CLJS.I(t,1,null);return $CLJS.I(t,2,null)},$CLJS.L7.g(d,e));if($CLJS.td(l))return null;if($CLJS.td(f))return $CLJS.Df(l);var m=$CLJS.eg.j($CLJS.N,$CLJS.Tk.g($CLJS.hf.h($CLJS.wW),$CLJS.LY(function(t,u){u=
$CLJS.s2.v(d,e,u,l);return $CLJS.n(u)?new $CLJS.P(null,2,5,$CLJS.Q,[u,t],null):null})),f);return $CLJS.Vk.g(function(t){var u=m.h?m.h(t):m.call(null,t);return $CLJS.n(u)?$CLJS.R.j(t,$CLJS.r0,u):t},l)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.N7=new $CLJS.h(null,2,[$CLJS.lG,$CLJS.eG,$CLJS.eG,$CLJS.lG],null);