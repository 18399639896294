var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var h1,Ina,Jna,i1,j1;$CLJS.f1=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.fc(c,d):c},$CLJS.ec($CLJS.Cf),b))};h1=function(a){return $CLJS.n(g1)?["(",$CLJS.p.h(a),")"].join(""):a};Ina=function(a,b,c){var d=$CLJS.LW(a,b);a=$CLJS.S0.j(a,b,d);var e=$CLJS.cB(c);return $CLJS.Re(function(f){return $CLJS.E.g($CLJS.cB($CLJS.T.h(f)),e)},a)};
Jna=function(a,b){var c=$CLJS.Wk.v(a,$CLJS.eQ,$CLJS.ZD($CLJS.be,$CLJS.Cf),b);return $CLJS.zd($CLJS.YE.h(a))?$CLJS.Wk.v(c,$CLJS.YE,$CLJS.be,$CLJS.vW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tB,$CLJS.N,$CLJS.EW(b)],null))):c};i1=function(a,b,c){var d=$CLJS.EW(c);return $CLJS.R.l($CLJS.g0.j(a,b,c),$CLJS.VK,$CLJS.LZ,$CLJS.H([$CLJS.T,d,$CLJS.tE,d]))};j1=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);
$CLJS.k1=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var l1=function(){function a(d,e,f){var k=$CLJS.LW(d,e);k=$CLJS.AH($CLJS.Tk.g($CLJS.ch([f]),$CLJS.EW),$CLJS.eQ.h(k));if($CLJS.n(k))return k;throw $CLJS.hi($CLJS.oE("No expression named {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.gS,f,$CLJS.LF,d,$CLJS.JL,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.L0.m(null,$CLJS.tB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=l1.j(a,b,c);return $CLJS.M0.j(a,b,c)});$CLJS.N0.m(null,$CLJS.tB,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.cu,$CLJS.TK,$CLJS.k1,$CLJS.FE.h(d),$CLJS.T,e,$CLJS.xE,e,$CLJS.tE,$CLJS.h0.j(a,b,c),$CLJS.wB,$CLJS.M0.j(a,b,c),$CLJS.VK,$CLJS.LZ],null)});$CLJS.H0.m(null,$CLJS.nB,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.H0.m(null,$CLJS.lB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.H0.m(null,$CLJS.mB,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.H0.m(null,$CLJS.DB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.H0.m(null,$CLJS.tB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.I0.m(null,$CLJS.tB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var g1=!1,Kna=new $CLJS.h(null,4,[$CLJS.fs,"+",$CLJS.ot,"-",$CLJS.gs,"×",$CLJS.JG,"÷"],null),m1=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fs,$CLJS.ot,$CLJS.JG,$CLJS.gs],null)),n1=null,o1=0,p1=0;;)if(p1<o1){var Lna=n1.X(null,p1);$CLJS.wF(Lna,j1);p1+=1}else{var q1=$CLJS.y(m1);if(q1){var r1=q1;if($CLJS.Ad(r1)){var s1=$CLJS.lc(r1),Mna=$CLJS.mc(r1),Nna=s1,Ona=$CLJS.D(s1);m1=Mna;n1=Nna;o1=Ona}else{var Pna=$CLJS.A(r1);$CLJS.wF(Pna,j1);m1=$CLJS.B(r1);n1=null;o1=0}p1=0}else break}
$CLJS.H0.m(null,j1,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.A(d);d=$CLJS.B(d);$CLJS.A(d);d=$CLJS.B(d);c=$CLJS.J.g(Kna,c);a:{var e=g1;g1=!0;try{var f=$CLJS.Gt([" ",$CLJS.gh(c)," "].join(""),$CLJS.hf.g($CLJS.fu($CLJS.h0,a,b),d));break a}finally{g1=e}f=void 0}return h1(f)});$CLJS.I0.m(null,j1,function(){return"expression"});
$CLJS.L0.m(null,$CLJS.HH,function(a,b,c){c=$CLJS.y(c);$CLJS.A(c);c=$CLJS.B(c);$CLJS.A(c);var d=$CLJS.B(c);return $CLJS.Qd($CLJS.yF,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=$CLJS.M0.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee($CLJS.M0.j(a,
b,u),k($CLJS.Lc(l)))}return null}},null,null)}(d)}())});$CLJS.H0.m(null,$CLJS.oJ,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.h0.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.$0.g(f,c).toLowerCase();0<f?f=$CLJS.fa.j?$CLJS.fa.j("+ %d %s",f,c):$CLJS.fa.call(null,"+ %d %s",f,c):(f=$CLJS.DA(f),f=$CLJS.fa.j?$CLJS.fa.j("- %d %s",f,c):$CLJS.fa.call(null,"- %d %s",f,c));f=h1(f);return[a," ",d.call(b,f)].join("")});
$CLJS.I0.m(null,$CLJS.oJ,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.J0.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.$0.g(e,c).toLowerCase();0<e?e=$CLJS.fa.j?$CLJS.fa.j("plus_%s_%s",e,c):$CLJS.fa.call(null,"plus_%s_%s",e,c):(e=$CLJS.DA(e),e=$CLJS.fa.j?$CLJS.fa.j("minus_%d_%s",e,c):$CLJS.fa.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.H0.m(null,$CLJS.rG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.h0.v(a,b,e,d)});$CLJS.I0.m(null,$CLJS.rG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.J0.j(a,b,d)});
$CLJS.Qna=function(){function a(d,e,f,k){e=$CLJS.n(e)?e:-1;if($CLJS.n(Ina(d,e,f)))throw $CLJS.hi("Expression name conflicts with a column in the same query stage",new $CLJS.h(null,1,[$CLJS.gS,f],null));return $CLJS.WW.l(d,e,Jna,$CLJS.H([$CLJS.FW($CLJS.xW.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.Rna=function(){function a(d,e){var f=$CLJS.Fe($CLJS.eQ.h($CLJS.LW(d,e)));return null==f?null:$CLJS.Vk.g($CLJS.fu(i1,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.t1=function(){function a(d,e){return $CLJS.Fe($CLJS.eQ.h($CLJS.LW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.uW.m(null,$CLJS.tB,function(a){return a});
$CLJS.Sna=function(){function a(d,e,f){var k=$CLJS.eg.g($CLJS.N,$CLJS.hm(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EW(t),m],null)},$CLJS.t1.g(d,e))),l=$CLJS.LW(d,e);d=$CLJS.S0.j(d,e,l);return $CLJS.Fe($CLJS.f1(function(m){return $CLJS.Va(f)||$CLJS.Rk.g($CLJS.VK.h(m),$CLJS.LZ)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Tna=function(){function a(d,e,f){return $CLJS.wW(i1(d,e,l1.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();