var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var KY,Zla,MY,$la,ama,bma,OY,dma,ema,fma,gma,RY,SY,TY,UY,WY,ima,XY,YY,jma,kma,lma,QY,cma,ZY,$Y,hma,bZ;KY=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.bf(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
Zla=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj.j?$CLJS.pj.j(k,l,m):$CLJS.pj.call(null,k,l,m),$CLJS.rl.j?$CLJS.rl.j(k,l,m):$CLJS.rl.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj.g?$CLJS.pj.g(k,l):$CLJS.pj.call(null,k,l),$CLJS.rl.g?$CLJS.rl.g(k,l):$CLJS.rl.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj.h?$CLJS.pj.h(k):$CLJS.pj.call(null,k),$CLJS.rl.h?$CLJS.rl.h(k):$CLJS.rl.call(null,
k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj.o?$CLJS.pj.o():$CLJS.pj.call(null),$CLJS.rl.o?$CLJS.rl.o():$CLJS.rl.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var z=Array(arguments.length-3);x<z.length;)z[x]=arguments[x+3],++x;x=new $CLJS.w(z,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Se.N($CLJS.pj,m,t,u,v),$CLJS.Se.N($CLJS.rl,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.A(m);m=$CLJS.B(m);var u=$CLJS.A(m);m=$CLJS.B(m);var v=$CLJS.A(m);m=$CLJS.Lc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};
$CLJS.LY=function(a){return function(b){var c=$CLJS.$e(-1);return function(){function d(l,m){var t=c.Dd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
MY=function(a){return $CLJS.WL($CLJS.rW)(a,$CLJS.Cf,$CLJS.Cf)};$la=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.dG,b),$CLJS.lt(2,2,c))};ama=function(a,b){var c=$CLJS.jm(a,$CLJS.MQ);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Fb(c);b=$CLJS.Ft.g?$CLJS.Ft.g(c,b):$CLJS.Ft.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.MQ,b)}return a};bma=function(a){return $CLJS.gf.g($CLJS.SV(a),$CLJS.TV(a))};
$CLJS.NY=function(a,b){return $CLJS.DW(a)&&$CLJS.E.g($CLJS.A(a),b)};OY=function(a,b,c){var d=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.Ts,null,$CLJS.xl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.Au(2,c):$CLJS.Au(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?KY(2,c):KY(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.CX.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.zd(k)?$CLJS.eg.j($CLJS.Cf,$CLJS.Uk.h($CLJS.El),l):$CLJS.xd(k)?$CLJS.LA($CLJS.Wa,l):l}):$CLJS.Lk.g(a,f)};
dma=function(a){return $CLJS.fb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return OY(b,cma,d)},a,bma(a))};
ema=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.uL.h(a),b);a:{var d=c;for(var e=$CLJS.Cf;;){var f=$CLJS.A($CLJS.hf.g(Zla(),$CLJS.lf($CLJS.Tk.j(PY,$CLJS.A,$CLJS.rl),$CLJS.Xs.h(MY(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=OY(d,f,k),m=$CLJS.OA($CLJS.Jy);if($CLJS.n($CLJS.NA("metabase.lib.convert",m))){var t=$CLJS.FB.l($CLJS.H([$CLJS.Ez,$CLJS.Mh.l($CLJS.H([k]))])),u=$CLJS.FB,v=u.l,x=$CLJS.Mh,z=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.aM(MY(d));$CLJS.MA("metabase.lib.convert",
m,$CLJS.AW("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.Ez,z.call(x,$CLJS.H([C]))])),$CLJS.FB.l($CLJS.H([$CLJS.tz,$CLJS.BW($CLJS.A($CLJS.hu(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.be.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=dma(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.uL.h(a))-1))return a;b+=1}else a=$CLJS.Wk.N(a,$CLJS.uL,$CLJS.R,b,d)}};
fma=function(a){var b=$CLJS.OW();return $CLJS.Vk.g(function(c){return $CLJS.E.g($CLJS.bF.h(c),"__join")?$CLJS.Wk.j(c,$CLJS.bF,b):c},a)};gma=function(a){return"string"===typeof $CLJS.kP.h(a)?$CLJS.Lk.g($CLJS.R.j(a,$CLJS.UV,$CLJS.MW($CLJS.kP.h(a))),$CLJS.kP):a};RY=function(a,b){var c=$CLJS.J.j(a,b,QY);if($CLJS.E.g(c,QY))throw $CLJS.hi(["Unable to find ",$CLJS.Mh.l($CLJS.H([b]))," in map."].join(""),new $CLJS.h(null,2,[hma,a,$CLJS.$w,b],null));return c};
SY=function(){return $CLJS.Uk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.me(b))?(b=$CLJS.ie(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};TY=function(a){return $CLJS.eg.j($CLJS.N,SY(),a)};UY=function(a){return $CLJS.Fe($CLJS.eg.j($CLJS.N,$CLJS.Tk.g(SY(),$CLJS.Uk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.Hi)})),a))};
WY=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.h($CLJS.VY),c);a=$CLJS.E.g(a,$CLJS.YF)&&$CLJS.B(c)?$CLJS.be.g($CLJS.sd(d),new $CLJS.h(null,1,[$CLJS.ci,$CLJS.rd(d)],null)):d;b=$CLJS.Fe(UY(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AM,a,b],null):a};ima=function(a){return $CLJS.eg.j($CLJS.Cf,$CLJS.Tk.g($CLJS.hf.h(function(b){return $CLJS.GA(b,$CLJS.KB)}),$CLJS.hf.h($CLJS.VY)),$CLJS.IW.h(a))};
XY=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.uL);a=$CLJS.A($CLJS.fb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.VY.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.OQ,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.XO,ima(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.tV.h(c)],null)},null,a));return $CLJS.n($CLJS.pQ.h(a))?$CLJS.FV(a,new $CLJS.h(null,1,[$CLJS.pQ,$CLJS.LF],null)):a};
YY=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.$W(a,b,$CLJS.Tk.g($CLJS.VY,$CLJS.A)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.$W(d,b,function(e){return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Fs],null),$CLJS.hf.h($CLJS.VY),e)}):d;return $CLJS.FV(a,$CLJS.Ee([b,c]))};jma=function(a){var b=$CLJS.UV.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Lk.g(a,$CLJS.UV),$CLJS.kP,["card__",$CLJS.p.h(b)].join("")):a};
kma=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);lma=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);QY=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);cma=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);ZY=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
$Y=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.aZ=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);hma=new $CLJS.M(null,"m","m",1632677161);bZ=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var PY,mma,nma,oma,pma,qma,rma,sma,tma,uma,vma;$CLJS.cZ=$CLJS.N;$CLJS.dZ=$CLJS.N;PY=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.oW,null,$CLJS.YE,null,$CLJS.JN,null,$CLJS.eQ,null,$CLJS.cG,null,$CLJS.UR,null,$CLJS.dG,null],null),null);mma=$CLJS.Xe($CLJS.N);nma=$CLJS.Xe($CLJS.N);oma=$CLJS.Xe($CLJS.N);pma=$CLJS.Xe($CLJS.N);qma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.kj,$CLJS.lC],null),$CLJS.kj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
$CLJS.eZ=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.jC,qma,mma,nma,oma,pma);$CLJS.eZ.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){var b=$CLJS.rd(a);var c=$CLJS.xd(b);a=c?$CLJS.sd(a):a;var d=$CLJS.y(a);a=$CLJS.A(d);d=$CLJS.B(d);b=$CLJS.vW($CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.hf.h($CLJS.eZ),d))}else b=a;return b});$CLJS.eZ.m(null,$CLJS.nW,function(a){return a});
$CLJS.eZ.m(null,$CLJS.aW,function(a){var b=$CLJS.eZ.h($CLJS.dG.h(a)),c=$CLJS.Fe($CLJS.Vk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.FW($CLJS.eZ.h(t),u)},$CLJS.eQ.h(a))),d=$CLJS.eg.j($CLJS.N,$CLJS.km(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.FE);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.eg.j($CLJS.N,$CLJS.km(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.FE);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.dZ,k=$CLJS.cZ;$CLJS.dZ=d;$CLJS.cZ=e;try{var l=$la(gma(a),b,$CLJS.H([$CLJS.eQ,c])),m=$CLJS.fb(function(t,u){return $CLJS.Va($CLJS.J.g(t,u))?t:$CLJS.Wk.j(t,u,$CLJS.eZ)},l,$CLJS.Mk.l(PY,$CLJS.dG,$CLJS.H([$CLJS.eQ])));return $CLJS.n($CLJS.JN.h(m))?$CLJS.Wk.j(m,$CLJS.JN,fma):m}finally{$CLJS.cZ=k,$CLJS.dZ=f}});$CLJS.eZ.m(null,$CLJS.hW,function(a){return ama(a,function(b){return $CLJS.$W(b,$CLJS.IF,$CLJS.eZ)})});
$CLJS.eZ.m(null,$CLJS.pL,function(a){a=$CLJS.Wk.j($CLJS.Wk.j(a,$CLJS.xL,$CLJS.eZ),$CLJS.uL,$CLJS.eZ);var b=$CLJS.n($CLJS.YE.h(a))?$CLJS.Wk.j(a,$CLJS.YE,function(c){return $CLJS.Ql(c)?$CLJS.Vk.g($CLJS.eZ,c):$CLJS.zh.h(c)}):a;return $CLJS.Va($CLJS.bF.h(a))?$CLJS.R.j(b,$CLJS.bF,"__join"):b});$CLJS.eZ.m(null,$CLJS.pB,function(a){return $CLJS.Vk.g($CLJS.eZ,a)});
$CLJS.eZ.m(null,$CLJS.rB,function(a){return $CLJS.n($CLJS.pj.h(a))?ema($CLJS.R.j($CLJS.Wk.j($CLJS.JW(a),$CLJS.uL,function(b){return $CLJS.Vk.g($CLJS.eZ,b)}),$CLJS.aZ,!0)):$CLJS.Ft(a,$CLJS.eZ)});$CLJS.eZ.m(null,$CLJS.oG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.vW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oG,a,b],null))});
$CLJS.eZ.m(null,$CLJS.Gj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.FV(a,new $CLJS.h(null,3,[$CLJS.kD,$CLJS.wB,$CLJS.JO,$CLJS.Yj,$CLJS.cS,$CLJS.AE],null));var c=$CLJS.R.j;var d=$CLJS.Hi.h(a);$CLJS.n(d)||(d=$CLJS.wB.h(a),d=$CLJS.n(d)?d:$CLJS.TE(b));a=c.call($CLJS.R,a,$CLJS.Hi,d);return $CLJS.vW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gj,a,b],null))});
$CLJS.eZ.m(null,$CLJS.YF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.ci.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YF,$CLJS.Lk.g(c,$CLJS.ci),$CLJS.Vk.g($CLJS.eZ,b)],null);b=$CLJS.vW(b);return null!=a?$CLJS.be.g(b,$CLJS.eZ.h(a)):b});$CLJS.eZ.m(null,$CLJS.tB,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.vW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.eZ.m(null,$CLJS.dG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.vW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,RY($CLJS.dZ,c)],null))});$CLJS.eZ.m(null,$CLJS.AM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.eZ.h(b);var c=$CLJS.y(b);b=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.kl.l($CLJS.H([c,a]))],null),d)});rma=$CLJS.Xe($CLJS.N);sma=$CLJS.Xe($CLJS.N);
tma=$CLJS.Xe($CLJS.N);uma=$CLJS.Xe($CLJS.N);vma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.kj,$CLJS.lC],null),$CLJS.kj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.VY=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.jC,vma,rma,sma,tma,uma);
$CLJS.VY.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);if($CLJS.xd(a)){c=$CLJS.eg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.gf.g;d=$CLJS.hf.g($CLJS.VY,d);a=UY(a);a=c.call($CLJS.eg,b,e.call($CLJS.gf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.hf.g($CLJS.VY,$CLJS.ee(a,d)))}else a=$CLJS.xd(a)?$CLJS.Ft(TY(a),$CLJS.VY):
a;return a});for(var fZ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[bZ,$Y],null)),gZ=null,hZ=0,iZ=0;;)if(iZ<hZ){var wma=gZ.X(null,iZ);$CLJS.wF(wma,ZY);iZ+=1}else{var jZ=$CLJS.y(fZ);if(jZ){var kZ=jZ;if($CLJS.Ad(kZ)){var lZ=$CLJS.lc(kZ),xma=$CLJS.mc(kZ),yma=lZ,zma=$CLJS.D(lZ);fZ=xma;gZ=yma;hZ=zma}else{var Ama=$CLJS.A(kZ);$CLJS.wF(Ama,ZY);fZ=$CLJS.B(kZ);gZ=null;hZ=0}iZ=0}else break}
for(var mZ=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Wx,$CLJS.iG,$CLJS.xG,$CLJS.UF,$CLJS.nk,$CLJS.CG,$CLJS.co,$CLJS.$F,$CLJS.vG,$CLJS.DG,$CLJS.BG,$CLJS.IG],null)),nZ=null,oZ=0,pZ=0;;)if(pZ<oZ){var Bma=nZ.X(null,pZ);$CLJS.wF(Bma,bZ);pZ+=1}else{var qZ=$CLJS.y(mZ);if(qZ){var rZ=qZ;if($CLJS.Ad(rZ)){var sZ=$CLJS.lc(rZ),Cma=$CLJS.mc(rZ),Dma=sZ,Ema=$CLJS.D(sZ);mZ=Cma;nZ=Dma;oZ=Ema}else{var Fma=$CLJS.A(rZ);$CLJS.wF(Fma,bZ);mZ=$CLJS.B(rZ);nZ=null;oZ=0}pZ=0}else break}
for(var tZ=$CLJS.y($CLJS.yf([$CLJS.fs,$CLJS.ot,$CLJS.gs,$CLJS.JG,$CLJS.YF,$CLJS.rG,$CLJS.PF,$CLJS.LG,$CLJS.GF,$CLJS.bG,$CLJS.KF,$CLJS.mG,$CLJS.tG,$CLJS.MF,$CLJS.eJ,$CLJS.gJ,$CLJS.Ky,$CLJS.aJ,$CLJS.iJ,$CLJS.VI,$CLJS.mJ,$CLJS.$I,$CLJS.TI,$CLJS.lJ,$CLJS.tJ,$CLJS.pJ,$CLJS.ZI,$CLJS.wJ,$CLJS.oJ,$CLJS.uJ,$CLJS.OF,$CLJS.HF,$CLJS.MG,$CLJS.eI,$CLJS.qG,$CLJS.Ux,$CLJS.EG,$CLJS.GG,$CLJS.NG,$CLJS.It,$CLJS.Ot],!0)),uZ=null,vZ=0,wZ=0;;)if(wZ<vZ){var Gma=uZ.X(null,wZ);$CLJS.wF(Gma,$Y);wZ+=1}else{var xZ=$CLJS.y(tZ);
if(xZ){var yZ=xZ;if($CLJS.Ad(yZ)){var zZ=$CLJS.lc(yZ),Hma=$CLJS.mc(yZ),Ima=zZ,Jma=$CLJS.D(zZ);tZ=Hma;uZ=Ima;vZ=Jma}else{var Kma=$CLJS.A(yZ);$CLJS.wF(Kma,$Y);tZ=$CLJS.B(yZ);uZ=null;vZ=0}wZ=0}else break}$CLJS.VY.m(null,ZY,function(a){return WY(a)});$CLJS.VY.m(null,$CLJS.rB,function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Tk.g(SY(),$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.VY.h(b)],null)})),a)});
$CLJS.VY.m(null,$CLJS.dG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.xd(b))try{var d=UY(b),e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dG,RY($CLJS.cZ,c)],null);return $CLJS.n(d)?$CLJS.be.g(e,d):e}catch(f){throw b=f,c=$CLJS.yV(b),c=$CLJS.fa.g?$CLJS.fa.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.fa.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.gi(c,new $CLJS.h(null,1,[$CLJS.W,a],null),b);}else return a});
$CLJS.VY.m(null,$CLJS.pB,function(a){return $CLJS.Vk.g($CLJS.VY,a)});$CLJS.VY.m(null,$CLJS.oG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oG,$CLJS.VY.h(a),UY(b)],null)});
$CLJS.VY.m(null,$CLJS.Gj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=UY($CLJS.FV(b,new $CLJS.h(null,3,[$CLJS.wB,$CLJS.kD,$CLJS.Yj,$CLJS.JO,$CLJS.AE,$CLJS.cS],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gj,a,b],null)});
$CLJS.VY.m(null,$CLJS.pL,function(a){var b=TY(a);a=0==$CLJS.bF.h(a).lastIndexOf("__join",0)?$CLJS.Lk.g(b,$CLJS.bF):b;return $CLJS.kl.l($CLJS.H([$CLJS.Ft($CLJS.Lk.l(a,$CLJS.uL,$CLJS.H([$CLJS.xL])),$CLJS.VY),YY($CLJS.im(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.xL],null)),$CLJS.xL,$CLJS.WR),XY(a)]))});
$CLJS.VY.m(null,$CLJS.aW,function(a){var b=$CLJS.dG.h(a),c=$CLJS.eg.j($CLJS.N,$CLJS.km(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.FE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.km(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.FE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.dZ,e=$CLJS.cZ;$CLJS.dZ=c;$CLJS.cZ=b;try{return $CLJS.fb(function(f,k){return $CLJS.$W(f,
k,$CLJS.VY)},YY($CLJS.$W($CLJS.$W(jma(TY(a)),$CLJS.dG,function(f){return $CLJS.Vk.g(WY,f)}),$CLJS.eQ,function(f){return $CLJS.eg.g($CLJS.N,function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z),G=$CLJS.VY.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EW(C),$CLJS.E.g($CLJS.Gj,$CLJS.A(G))?$CLJS.hd(G):G],null);x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),
m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);u=$CLJS.VY.h(x);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EW(x),$CLJS.E.g($CLJS.Gj,$CLJS.A(u))?$CLJS.hd(u):u],null),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())}),$CLJS.oW,$CLJS.TF),$CLJS.Mk.l(PY,$CLJS.dG,$CLJS.H([$CLJS.oW,$CLJS.eQ])))}finally{$CLJS.cZ=e,$CLJS.dZ=d}});$CLJS.VY.m(null,$CLJS.hW,function(a){return $CLJS.Ft(TY(a),$CLJS.VY)});
$CLJS.VY.m(null,$CLJS.nW,function(a){try{var b=TY(a),c=$CLJS.AN.h(b),d=XY(b),e=$CLJS.E.g($CLJS.cu.h($CLJS.id($CLJS.uL.h(a))),$CLJS.hW)?$CLJS.pQ:$CLJS.LF;return $CLJS.kl.l($CLJS.H([$CLJS.Ft($CLJS.Lk.l(b,$CLJS.uL,$CLJS.H([$CLJS.AN,$CLJS.aZ])),$CLJS.VY),function(){var k=$CLJS.Ee([$CLJS.pj,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.AN,c):k}()]))}catch(k){var f=k;throw $CLJS.gi(function(){var l=$CLJS.yV(f);return $CLJS.fa.g?$CLJS.fa.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.fa.call(null,
"Error converting MLv2 query to legacy query: %s",l)}(),new $CLJS.h(null,1,[$CLJS.LF,a],null),f);}});
$CLJS.AZ=function(){function a(d,e,f){f=$CLJS.JY(null,$CLJS.Rz(f,$CLJS.H([$CLJS.Bi,!0])));var k=$CLJS.LW(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.dG);k=$CLJS.eg.j($CLJS.N,$CLJS.km(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.FE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.eg.j($CLJS.N,$CLJS.km(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.FE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.dZ,t=$CLJS.cZ;$CLJS.dZ=k;$CLJS.cZ=l;try{try{return $CLJS.eZ.h(f)}catch(v){var u=v;throw $CLJS.gi(function(){var x=$CLJS.yV(u);return $CLJS.fa.g?$CLJS.fa.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.fa.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.LF,d,$CLJS.JL,e,lma,f,kma,$CLJS.dZ],null),u);}}finally{$CLJS.cZ=t,$CLJS.dZ=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();