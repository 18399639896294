var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var m4,o4,p4,q4,Opa;m4=function(a,b,c){var d=$CLJS.LW(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.dG);d=$CLJS.AH($CLJS.Tk.j($CLJS.ch([c]),$CLJS.FE,$CLJS.hd),d);if(!$CLJS.n(d))throw $CLJS.hi($CLJS.oE("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.gk,c,$CLJS.LF,a,$CLJS.JL,b],null));return d};$CLJS.n4=function(a){return $CLJS.g2($CLJS.BG,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};
o4=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);p4=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);q4=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);Opa=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.K0.m(null,$CLJS.dG,function(a,b){var c=$CLJS.Fe($CLJS.dG.h($CLJS.LW(a,b)));return $CLJS.n(c)?$CLJS.i2($CLJS.QE("and"),function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.h0.v(a,b,v,$CLJS.i0);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.A(k);return $CLJS.ee($CLJS.h0.v(a,
b,t,$CLJS.i0),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):null});$CLJS.N0.m(null,$CLJS.dG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.wB);e=$CLJS.J.g(e,$CLJS.Hi);c=$CLJS.I(c,2,null);c=m4(a,b,c);return $CLJS.kl.l($CLJS.H([$CLJS.g0.j(a,b,c),new $CLJS.h(null,2,[$CLJS.VK,$CLJS.nL,$CLJS.k1,$CLJS.FE.h($CLJS.hd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.wB,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Hi,e],null):null]))});
$CLJS.H0.m(null,$CLJS.dG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.h0.v(a,b,m4(a,b,c),d)});$CLJS.wF(p4,q4);
for(var r4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wx,$CLJS.pG],null)),s4=null,t4=0,u4=0;;)if(u4<t4){var Ppa=s4.X(null,u4);$CLJS.wF(Ppa,p4);u4+=1}else{var v4=$CLJS.y(r4);if(v4){var w4=v4;if($CLJS.Ad(w4)){var x4=$CLJS.lc(w4),Qpa=$CLJS.mc(w4),Rpa=x4,Spa=$CLJS.D(x4);r4=Qpa;s4=Rpa;t4=Spa}else{var Tpa=$CLJS.A(w4);$CLJS.wF(Tpa,p4);r4=$CLJS.B(w4);s4=null;t4=0}u4=0}else break}
$CLJS.H0.m(null,p4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.h0.v(a,b,c,d),e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.oE("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.oE("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.QE("Count");case "cum-count":return $CLJS.QE("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.I0.m(null,p4,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.N0.m(null,p4,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.tI($CLJS.N0,q4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.Yj,$CLJS.ZC)});$CLJS.H0.m(null,$CLJS.YF,function(){return $CLJS.QE("Case")});
$CLJS.I0.m(null,$CLJS.YF,function(){return"case"});$CLJS.wF(o4,q4);for(var y4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.iG,$CLJS.KG,$CLJS.UF,$CLJS.nk,$CLJS.CG,$CLJS.co,$CLJS.DG,$CLJS.BG,$CLJS.VF],null)),z4=null,A4=0,B4=0;;)if(B4<A4){var Upa=z4.X(null,B4);$CLJS.wF(Upa,o4);B4+=1}else{var C4=$CLJS.y(y4);if(C4){var D4=C4;if($CLJS.Ad(D4)){var E4=$CLJS.lc(D4),Vpa=$CLJS.mc(D4),Wpa=E4,Xpa=$CLJS.D(E4);y4=Vpa;z4=Wpa;A4=Xpa}else{var Ypa=$CLJS.A(D4);$CLJS.wF(Ypa,o4);y4=$CLJS.B(D4);z4=null;A4=0}B4=0}else break}
$CLJS.I0.m(null,o4,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.H0.m(null,o4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.h0.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "avg":return $CLJS.oE("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.oE("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.oE("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.oE("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.oE("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.oE("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.oE("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.oE("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.oE("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.H0.m(null,$CLJS.$F,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.oE("{0}th percentile of {1}",$CLJS.H([c,$CLJS.h0.v(a,b,e,d)]))});$CLJS.I0.m(null,$CLJS.$F,function(){return"percentile"});
$CLJS.wF($CLJS.$F,q4);$CLJS.H0.m(null,$CLJS.IG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.oE("Sum of {0} matching condition",$CLJS.H([$CLJS.h0.v(a,b,e,d)]))});$CLJS.I0.m(null,$CLJS.IG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.J0.j(a,b,d))].join("")});$CLJS.wF($CLJS.IG,q4);$CLJS.H0.m(null,$CLJS.vG,function(){return $CLJS.QE("Share of rows matching condition")});
$CLJS.I0.m(null,$CLJS.vG,function(){return"share"});$CLJS.wF($CLJS.vG,q4);$CLJS.H0.m(null,$CLJS.xG,function(){return $CLJS.QE("Count of rows matching condition")});$CLJS.I0.m(null,$CLJS.xG,function(){return"count-where"});$CLJS.wF($CLJS.xG,q4);
$CLJS.N0.m(null,q4,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.kl.l;d=$CLJS.n(d)?$CLJS.im($CLJS.g0.j(a,b,d),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.gL],null)):null;var f=$CLJS.tI($CLJS.N0,$CLJS.ci);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.kl,$CLJS.H([d,a]))});
$CLJS.Zpa=function(){function a(d){return $CLJS.g2($CLJS.Wx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.g2($CLJS.Wx,$CLJS.Cf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.uW.m(null,$CLJS.dG,function(a){return a});
$CLJS.F4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.jC(f),$CLJS.ZK))f=$CLJS.wW(f);else return $CLJS.j2(d,e,$CLJS.dG,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.G4=function(){function a(d,e){return $CLJS.Fe($CLJS.dG.h($CLJS.LW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.H4=function(){function a(d,e){var f=$CLJS.Fe($CLJS.dG.h($CLJS.LW(d,e)));return null==f?null:$CLJS.eg.j($CLJS.Cf,$CLJS.hf.h(function(k){var l=$CLJS.g0.j(d,e,k),m=$CLJS.R.l,t=$CLJS.wB.h(l);return m.call($CLJS.R,$CLJS.f2(l,$CLJS.Hi,$CLJS.n(t)?t:$CLJS.Dj),$CLJS.VK,$CLJS.nL,$CLJS.H([$CLJS.k1,$CLJS.FE.h($CLJS.hd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.H0.m(null,$CLJS.XG,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.TG);return $CLJS.tE.h(a.o?a.o():a.call(null))});$CLJS.O0.m(null,$CLJS.XG,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.hG);b=$CLJS.J.g(d,$CLJS.TG);c=$CLJS.J.g(d,$CLJS.RG);d=$CLJS.J.g(d,$CLJS.TZ);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.F0,$CLJS.bB(a),$CLJS.H([$CLJS.D0,c]));return null!=d?$CLJS.R.j(a,$CLJS.p0,d):a});
$CLJS.$pa=function(){function a(d,e){var f=function(){var m=$CLJS.kA.h($CLJS.B1($CLJS.a0(d)));return $CLJS.n(m)?m:$CLJS.bh}(),k=$CLJS.LW(d,e),l=$CLJS.S0.j(d,e,k);return $CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.Tk.j($CLJS.kf(function(m){m=$CLJS.VG.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.am(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.RG),u=$CLJS.J.g(m,$CLJS.PG);if($CLJS.Va(t))return m;if($CLJS.E.g(u,$CLJS.li))return $CLJS.R.j(m,$CLJS.IW,l);t=$CLJS.Fe($CLJS.f1(function(v){return $CLJS.D_(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.IW,t):null}),$CLJS.hf.h(function(m){return $CLJS.R.j(m,$CLJS.cu,$CLJS.XG)})),$CLJS.YG))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.aqa=function(){function a(d,e){return $CLJS.vW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG.h(d),$CLJS.N,$CLJS.xW.h(e)],null))}function b(d){if($CLJS.Va($CLJS.RG.h(d)))return $CLJS.vW(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hG.h(d),$CLJS.N],null));var e=$CLJS.hG.h(d);e=$CLJS.fa.g?$CLJS.fa.g("aggregation operator %s requires an argument",e):$CLJS.fa.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.hi(e,new $CLJS.h(null,1,[Opa,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();