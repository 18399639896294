var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var XL,YL,ZL,Dha,Eha,cM,Fha,fM,gM,hM,iM,Gha,jM,kM,mM,nM,$L,oM,Hha,pM,Iha,qM,rM,sM,Jha,tM,uM,Kha,vM,Lha,wM,xM,Mha,zM,Nha,Oha,Pha,BM,CM,DM,EM,FM,GM,HM,IM,Qha,JM,KM,LM,NM,OM,Rha,PM,QM,RM,SM,Sha,TM,UM,VM,WM,Tha,XM,YM,ZM,$M,Uha,aN,Vha,bN,cN,dN,Wha,eN,fN,gN,hN,jN,kN,Xha,lN,mN,Yha,nN,oN,pN,qN,rN,Zha,sN,$ha,tN,uN,wN,aia,xN,yN,zN,bia,CN,DN,cia,dia,EN,GN,HN,IN,eia,LN,MN,fia,gia,hia,NN,ON,PN,QN,RN,SN,iia,TN,jia,UN,VN,XN,ZN,$N,aO,bO,kia,lia,dO,mia,nia,eO,fO,gO,hO,iO,oia,jO,pia,qia,kO,lO,mO,nO,oO,
ria,sia,tia,pO,uia,qO,via,sO,tO,wia,vO,wO,xO,yO,xia,BO,yia,zia,CO,DO,EO,Aia,FO,GO,HO,IO,Bia,KO,LO,MO,NO,OO,Cia,PO,QO,Dia,RO,SO,TO,UO,VO,WO,YO,Eia,ZO,Fia,$O,aP,bP,Gia,Hia,Iia,dP,eP,fP,Jia,gP,hP,iP,jP,Kia,Lia,lP,Mia,Nia,mP,nP,oP,pP,qP,Oia,rP,sP,tP,uP,Pia,vP,wP,xP,yP,AP,Qia,BP,CP,Ria,DP,EP,FP,Sia,GP,Tia,IP,Uia,JP,KP,LP,MP,NP,OP,PP,Via,Wia,Xia,QP,Yia,RP,SP,Zia,$ia,TP,UP,VP,aja,WP,bja,XP,cja,dja,YP,eja,fja,gja,aQ,bQ,hja,cQ,dQ,fQ,ija,gQ,jja,hQ,iQ,kQ,kja,lja,lQ,mQ,nQ,oQ,mja,qQ,rQ,nja,sQ,tQ,uQ,vQ,wQ,xQ,yQ,
zQ,oja,AQ,BQ,pja,CQ,qja,DQ,EQ,FQ,rja,GQ,HQ,sja,tja,IQ,JQ,KQ,uja,vja,LQ,wja,xja,yja,NQ,zja,PQ,Aja,Bja,QQ,RQ,Cja,Dja,Eja,SQ,TQ,UQ,VQ,WQ,XQ,YQ,Fja,ZQ,$Q,Gja,aR,Hja,Ija,Jja,bR,cR,dR,Kja,eR,fR,Lja,gR,Mja,hR,Nja,Oja,jR,kR,lR,mR,nR,Pja,oR,pR,Qja,qR,rR,sR,tR,uR,vR,wR,xR,Rja,yR,zR,BR,CR,DR,ER,FR,GR,HR,IR,JR,KR,Sja,LR,MR,NR,OR,PR,Tja,QR,RR,SR,TR,Uja,VR,Vja,Wja,YR,Xja,ZR,Yja,$R,Zja,$ja,aS,aka,bS,dS,eS,bka,cka,iS,jS,dka,eka,kS,lS,mS,nS,oS,fka,pS,qS,sS,tS,gka;
$CLJS.WL=function(a){var b=$CLJS.Co.g(a,null),c=$CLJS.sF(b,$CLJS.Rs,function(d){return $CLJS.Bn(d,$CLJS.Cf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.ul,b,$CLJS.Gj,k,$CLJS.Xs,l],null):null}function e(k){return f.j(k,$CLJS.Cf,$CLJS.Cf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
XL=function(a,b){return $CLJS.xd(a)?$CLJS.J.g(a,b):a};YL=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.En($CLJS.Co.g($CLJS.ul.h(a),null));if($CLJS.n(b)){var e=XL($CLJS.xt.h(b),c);$CLJS.n(e)?(e=$CLJS.Mq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:XL($CLJS.vt.h(b),c)}return null};ZL=function(a,b){return $CLJS.vd(a)||$CLJS.Nl(a)?$CLJS.J.g(a,b):$CLJS.wd(a)?$CLJS.J.g($CLJS.Df(a),b):null};
Dha=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.ul),d=$CLJS.J.g(a,$CLJS.pj);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.Xs,$CLJS.nea),f=$CLJS.J.j(b,$CLJS.iea,!0),k=$CLJS.J.g(b,$CLJS.iC),l=$CLJS.J.j(b,$CLJS.gC,$CLJS.eC),m=YL(a,$CLJS.Yq.h(c),k,b);if($CLJS.n(m))return m;m=YL(a,$CLJS.it.h(c),k,b);if($CLJS.n(m))return m;m=YL(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=YL(a,function(){var t=$CLJS.jo.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=YL(a,$CLJS.Yq.h(c),
l,b);if($CLJS.n(m))return m;m=YL(a,$CLJS.it.h(c),l,b);if($CLJS.n(m))return m;d=YL(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=YL(a,function(){var t=$CLJS.jo.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?YL(a,e.h?e.h($CLJS.cC):e.call(null,$CLJS.cC),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?YL(a,e.h?e.h($CLJS.cC):e.call(null,$CLJS.cC),l,b):f};
Eha=function(a,b,c){a=$CLJS.Q;var d=$CLJS.eg.g;var e=$CLJS.rl.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.ul),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.iC);l=$CLJS.J.j(l,$CLJS.gC,$CLJS.eC);k=$CLJS.Yq.h(k);f=XL($CLJS.fC.h(k),f);f=$CLJS.n(f)?f:XL($CLJS.fC.h(k),l);d=d.call($CLJS.eg,e,f);return new $CLJS.P(null,2,5,a,[d,Dha(b,c)],null)};
$CLJS.aM=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Gj);a=$CLJS.J.g(b,$CLJS.Xs);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.jea,$CLJS.Ti),f=$CLJS.J.j(d,$CLJS.lea,Eha);return $CLJS.n(a)?$CLJS.fb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Ti,m);l=e.h?e.h(l):e.call(null,l);return $L(k,c,t,l)},null,a):null};
cM=function(a,b,c){var d=$CLJS.FA($CLJS.q($CLJS.hE),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Oh.v($CLJS.hE,$CLJS.bM,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.dM=function(a){return cM($CLJS.Bk,a,function(){return $CLJS.tF.h(a)})};Fha=function(){var a=eM;return cM($CLJS.Rs,a,function(){var b=$CLJS.tF.h(a),c=$CLJS.WL(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
fM=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,$CLJS.me(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};gM=function(a){if($CLJS.zd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,fM(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,fM(c)],null);default:return fM(a)}}else return fM(a)};
hM=function(a,b){var c=$CLJS.zd(b);return c?(c=$CLJS.A(b)instanceof $CLJS.M)?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c:c};
iM=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.vt,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Ve(hM,a)],null),$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Li,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,a],null)],null)],null),function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,gM(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[m,gM(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}($CLJS.lt(2,2,b))}())],null)};Gha=function(a){return $CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M?$CLJS.A(a):null};
jM=function(a){return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.Ri,Gha,$CLJS.vt,["valid instance of one of these MBQL clauses: ",$CLJS.Gt(", ",$CLJS.hf.g($CLJS.A,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.me(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.me(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};
kM=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VL],null)],null)};$CLJS.lM={};mM={};nM={};$CLJS.bM=function bM(a,b,c){var e=$CLJS.y(b);b=$CLJS.A(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=bM.j?bM.j(k,f,c):bM.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
$L=function $L(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.A(f);var k=$CLJS.B(f),l=ZL(b,c);f=$CLJS.n(a)?a:$CLJS.wd(b)?$CLJS.Cf:$CLJS.yd(b)?$CLJS.N:$CLJS.jd(b);return $CLJS.n($CLJS.n(c)?$CLJS.dC.h($CLJS.pd(f)):c)?a:$CLJS.n(c)?(b=ZL(f,c),d=$L.v?$L.v(b,l,k,d):$L.call(null,b,l,k,d),$CLJS.Sl(c)&&$CLJS.wd(f)&&c>$CLJS.D(f)&&(b=$CLJS.df(c-$CLJS.D(f),null),b=$CLJS.gf.g(f,b),f=null==f||$CLJS.Dd(f)?b:$CLJS.eg.g($CLJS.jd(f),b)),null==f||$CLJS.Nl(f)?$CLJS.R.j(f,c,d):$CLJS.vd(f)?$CLJS.be.g(f,d):$CLJS.Se.g($CLJS.U,$CLJS.R.j($CLJS.Df(f),
c,d))):$CLJS.xd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kea],null),$L.v?$L.v(f,b,c,d):$L.call(null,f,b,c,d)):$CLJS.n($CLJS.dC.h($CLJS.pd(f)))?$CLJS.be.g(f,d):$CLJS.zd($CLJS.Fe(f))?f:$CLJS.od(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.dC,!0],null))};oM=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Hha=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
pM=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);Iha=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);qM=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);rM=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);sM=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
Jha=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);tM=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);uM=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);Kha=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);vM=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
Lha=new $CLJS.M(null,"from","from",1815293044);wM=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);xM=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);Mha=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.yM=new $CLJS.M(null,"snippet-name","snippet-name",819240328);zM=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
Nha=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Oha=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.AM=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);Pha=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);BM=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
CM=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);DM=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);EM=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);FM=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);GM=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
HM=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);IM=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Qha=new $CLJS.M(null,"lon-max","lon-max",1590224717);JM=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);KM=new $CLJS.r(null,"stddev","stddev",775056588,null);LM=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.MM=new $CLJS.M(null,"snippet","snippet",953581994);NM=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);OM=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Rha=new $CLJS.M(null,"lat-field","lat-field",-830652957);PM=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);QM=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
RM=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);SM=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Sha=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);TM=new $CLJS.M("location","country","location/country",1666636202);UM=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
VM=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);WM=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Tha=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);XM=new $CLJS.M(null,"unary","unary",-989314568);YM=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
ZM=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);$M=new $CLJS.r(null,"ceil","ceil",-184398425,null);Uha=new $CLJS.M(null,"lon-min","lon-min",-787291357);aN=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Vha=new $CLJS.M(null,"match","match",1220059550);bN=new $CLJS.r(null,"count-where","count-where",2025939247,null);cN=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
dN=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Wha=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);eN=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);fN=new $CLJS.r(null,"sum","sum",1777518341,null);gN=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);hN=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.iN=new $CLJS.M("date","range","date/range",1647265776);jN=new $CLJS.r(null,"between","between",-1523336493,null);kN=new $CLJS.M(null,"clause-form","clause-form",1820463737);Xha=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);lN=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);mN=new $CLJS.r(null,"field","field",338095027,null);Yha=new $CLJS.M(null,"segment-id","segment-id",1810133590);
nN=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);oN=new $CLJS.r(null,"not-null","not-null",313812992,null);pN=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);qN=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);rN=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Zha=new $CLJS.M(null,"template-tag","template-tag",310841038);
sN=new $CLJS.M(null,"invalid","invalid",412869516);$ha=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);tN=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);uN=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.vN=new $CLJS.M(null,"context","context",-830191113);wN=new $CLJS.r(null,"get-year","get-year",704520253,null);
aia=new $CLJS.M(null,"format-rows?","format-rows?",992129486);xN=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);yN=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);zN=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.AN=new $CLJS.M(null,"parameters","parameters",-1229919748);bia=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.BN=new $CLJS.M(null,"card","card",-1430355152);CN=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);DN=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);cia=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);dia=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);EN=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.FN=new $CLJS.M("date","month-year","date/month-year",1948031290);
GN=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);HN=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);IN=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.JN=new $CLJS.M(null,"joins","joins",1033962699);eia=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.KN=new $CLJS.M(null,"source-field","source-field",933829534);LN=new $CLJS.r(null,"Field","Field",430385967,null);
MN=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);fia=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);gia=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);hia=new $CLJS.M(null,"items","items",1031954938);NN=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);ON=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
PN=new $CLJS.M(null,"more","more",-2058821800);QN=new $CLJS.M(null,"first-clause","first-clause",-20953491);RN=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);SN=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);iia=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);TN=new $CLJS.r(null,"contains","contains",-1977535957,null);
jia=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);UN=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);VN=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.WN=new $CLJS.M(null,"widget-type","widget-type",1836256899);XN=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.YN=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
ZN=new $CLJS.r(null,"is-null","is-null",-356519403,null);$N=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);aO=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);bO=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);kia=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.cO=new $CLJS.M(null,"required","required",1807647006);lia=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
dO=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);mia=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);nia=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);eO=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);fO=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);gO=new $CLJS.M("string","contains","string/contains",1602423827);hO=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
iO=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);oia=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);jO=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);pia=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);qia=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
kO=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);lO=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);mO=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);nO=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);oO=new $CLJS.r(null,"share","share",1051097594,null);
ria=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);sia=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);tia=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);pO=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);uia=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
qO=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);via=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.rO=new $CLJS.M(null,"collection","collection",-683361892);sO=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);tO=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.uO=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);wia=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);vO=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);wO=new $CLJS.r(null,"metric","metric",2049329604,null);xO=new $CLJS.r(null,"concat","concat",-467652465,null);yO=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.zO=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
xia=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.AO=new $CLJS.M("date","relative","date/relative",25987732);BO=new $CLJS.M("location","city","location/city",-1746973325);yia=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);zia=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);CO=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);DO=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
EO=new $CLJS.M("number","between","number/between",97700581);Aia=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);FO=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);GO=new $CLJS.r(null,"sqrt","sqrt",370479598,null);HO=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);IO=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.JO=new $CLJS.M(null,"semantic_type","semantic_type",272485089);Bia=new $CLJS.M(null,"metric-id","metric-id",-686486942);KO=new $CLJS.r(null,"*","*",345799209,null);LO=new $CLJS.r(null,"+","+",-740910886,null);MO=new $CLJS.r(null,"-","-",-471816912,null);NO=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);OO=new $CLJS.M(null,"allowed-for","allowed-for",122724334);Cia=new $CLJS.M(null,"question","question",-1411720117);PO=new $CLJS.r(null,"asc","asc",1997386096,null);
QO=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);Dia=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);RO=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);SO=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);TO=new $CLJS.r(null,"\x3c","\x3c",993667236,null);UO=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);VO=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
WO=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.XO=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);YO=new $CLJS.M("string","ends-with","string/ends-with",302681156);Eia=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);ZO=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);Fia=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
$O=new $CLJS.r(null,"and","and",668631710,null);aP=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);bP=new $CLJS.r(null,"round","round",-645002441,null);Gia=new $CLJS.M(null,"to","to",192099007);$CLJS.cP=new $CLJS.M("date","single","date/single",1554682003);Hia=new $CLJS.M(null,"action-id","action-id",-1727958578);Iia=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);dP=new $CLJS.r(null,"exp","exp",1378825265,null);
eP=new $CLJS.r(null,"Filter","Filter",-424893332,null);fP=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);Jia=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);gP=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);hP=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);iP=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
jP=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.kP=new $CLJS.M(null,"source-table","source-table",-225307692);Kia=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);Lia=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);lP=new $CLJS.r(null,"floor","floor",-772394748,null);Mia=new $CLJS.M(null,"middleware","middleware",1462115504);
Nia=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);mP=new $CLJS.M(null,"requires-features","requires-features",-101116256);nP=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);oP=new $CLJS.M(null,"clause-name","clause-name",-996419059);pP=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);qP=new $CLJS.r(null,"now","now",-9994004,null);
Oia=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);rP=new $CLJS.r(null,"not","not",1044554643,null);sP=new $CLJS.r(null,"avg","avg",1837937727,null);tP=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);uP=new $CLJS.M(null,"max-results","max-results",-32858165);Pia=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);vP=new $CLJS.r(null,"case","case",-1510733573,null);
wP=new $CLJS.r(null,"distinct","distinct",-148347594,null);xP=new $CLJS.r(null,"get-second","get-second",-425414791,null);yP=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.zP=new $CLJS.M(null,"join-alias","join-alias",1454206794);AP=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Qia=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
BP=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);CP=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Ria=new $CLJS.M(null,"original","original",-445386197);DP=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);EP=new $CLJS.r(null,"abs","abs",1394505050,null);FP=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Sia=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
GP=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Tia=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.HP=new $CLJS.M(null,"date","date",-1463434462);IP=new $CLJS.M(null,"second-clause","second-clause",-461435645);Uia=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);JP=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
KP=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);LP=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);MP=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);NP=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);OP=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);PP=new $CLJS.r(null,"or","or",1876275696,null);
Via=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Wia=new $CLJS.M(null,"constraints","constraints",422775616);Xia=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);QP=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Yia=new $CLJS.M(null,"csv-download","csv-download",2141432084);RP=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
SP=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Zia=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);$ia=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);TP=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);UP=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
VP=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);aja=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);WP=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);bja=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);XP=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);cja=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
dja=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);YP=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);eja=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);fja=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);gja=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.ZP=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.$P=new $CLJS.M(null,"database","database",1849087575);aQ=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);bQ=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);hja=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);cQ=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
dQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.eQ=new $CLJS.M(null,"expressions","expressions",255689909);fQ=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);ija=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);gQ=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
jja=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);hQ=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);iQ=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);kQ=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);kja=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);lja=new $CLJS.M(null,"action","action",-811238024);
lQ=new $CLJS.r(null,"get-day","get-day",1768100384,null);mQ=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);nQ=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);oQ=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.pQ=new $CLJS.M(null,"native","native",-613060878);mja=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);qQ=new $CLJS.M(null,"page","page",849072397);
rQ=new $CLJS.r(null,"length","length",-2065447907,null);nja=new $CLJS.M(null,"dashboard","dashboard",-631747508);sQ=new $CLJS.r(null,"get-week","get-week",752472178,null);tQ=new $CLJS.r(null,"get-month","get-month",1271156796,null);uQ=new $CLJS.r(null,"dimension","dimension",-2111181571,null);vQ=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);wQ=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
xQ=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);yQ=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);zQ=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);oja=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);AQ=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);BQ=new $CLJS.r(null,"substring","substring",-1513569493,null);
pja=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);CQ=new $CLJS.M(null,"internal","internal",-854870097);qja=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);DQ=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);EQ=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
FQ=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);rja=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);GQ=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);HQ=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);sja=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
tja=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);IQ=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);JQ=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);KQ=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
uja=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);vja=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);LQ=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.MQ=new $CLJS.M(null,"template-tags","template-tags",1853115685);wja=new $CLJS.M(null,"public-question","public-question",629369976);xja=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
yja=new $CLJS.M(null,"binary","binary",-1802232288);NQ=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);zja=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.OQ=new $CLJS.M(null,"source-query","source-query",198004422);PQ=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);Aja=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
Bja=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);QQ=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);RQ=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);Cja=new $CLJS.M(null,"executed-by","executed-by",-739811161);Dja=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
Eja=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);SQ=new $CLJS.M(null,"amount","amount",364489504);TQ=new $CLJS.r(null,"percentile","percentile",1039342775,null);UQ=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);VQ=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);WQ=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
XQ=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);YQ=new $CLJS.r(null,"trim","trim",-1880116002,null);Fja=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);ZQ=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);$Q=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);Gja=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
aR=new $CLJS.M("string","\x3d","string/\x3d",983744235);Hja=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);Ija=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Jja=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);bR=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);cR=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
dR=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Kja=new $CLJS.M(null,"lat-min","lat-min",1630784161);eR=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);fR=new $CLJS.r(null,"inside","inside",-681932758,null);Lja=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);gR=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
Mja=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);hR=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);Nja=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);Oja=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.iR=new $CLJS.M(null,"card-id","card-id",-1770060179);jR=new $CLJS.M(null,"variadic","variadic",882626057);
kR=new $CLJS.r(null,"upper","upper",1886775433,null);lR=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);mR=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);nR=new $CLJS.r(null,"optional","optional",-600484260,null);Pja=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);oR=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
pR=new $CLJS.M(null,"sugar","sugar",-73788488);Qja=new $CLJS.M(null,"lat-max","lat-max",841568226);qR=new $CLJS.r(null,"power","power",702679448,null);rR=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);sR=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);tR=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
uR=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);vR=new $CLJS.r(null,"median","median",-2084869638,null);wR=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);xR=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Rja=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);yR=new $CLJS.M(null,"y","y",-1757859776);zR=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.AR=new $CLJS.M(null,"binning","binning",1709835866);BR=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);CR=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);DR=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);ER=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);FR=new $CLJS.r(null,"Reference","Reference",2024574086,null);GR=new $CLJS.M(null,"b","b",1482224470);
HR=new $CLJS.M(null,"a","a",-2123407586);IR=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);JR=new $CLJS.r(null,"replace","replace",853943757,null);KR=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Sja=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);LR=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
MR=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);NR=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);OR=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);PR=new $CLJS.r(null,"segment","segment",675610331,null);Tja=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);QR=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
RR=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);SR=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);TR=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Uja=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.UR=new $CLJS.M(null,"order-by","order-by",1527318070);VR=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Vja=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Wja=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.WR=new $CLJS.M(null,"condition","condition",1668437652);YR=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Xja=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);ZR=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Yja=new $CLJS.M(null,"card-name","card-name",-2035606807);$R=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Zja=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);$ja=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);aS=new $CLJS.r(null,"log","log",45015523,null);aka=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
bS=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.cS=new $CLJS.M(null,"database_type","database_type",-54700895);dS=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);eS=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.fS=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.gS=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.hS=new $CLJS.M("date","all-options","date/all-options",549325958);
bka=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);cka=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);iS=new $CLJS.M("location","state","location/state",-114378652);jS=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);dka=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);eka=new $CLJS.M(null,"lon-field","lon-field",517872067);
kS=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);lS=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);mS=new $CLJS.M(null,"numeric","numeric",-1495594714);nS=new $CLJS.r(null,"variable","variable",1359185035,null);oS=new $CLJS.r(null,"lower","lower",-1534114948,null);fka=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
pS=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);qS=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.rS=new $CLJS.M(null,"limit","limit",-1355822363);sS=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);tS=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);gka=new $CLJS.M(null,"pulse","pulse",-244494476);var uS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yE],null),vS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.HE],null),wS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uE],null),xS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qE],null),yS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CE],null),zS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lL],null),hka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cL],null),ika=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YK],null),AS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.NI],null),BS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RI],null),CS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.II],null),DS=new $CLJS.ah(null,new $CLJS.h(null,12,[$CLJS.pi,null,$CLJS.du,null,$CLJS.rj,null,$CLJS.ci,null,$CLJS.Ii,null,$CLJS.Aj,null,$CLJS.qk,null,$CLJS.Jj,null,$CLJS.ji,null,$CLJS.Hj,null,$CLJS.qi,null,$CLJS.Ci,null],null),null),ES=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.uk,null,$CLJS.aH,null,$CLJS.ci,null,$CLJS.wi,null,$CLJS.dH,null,$CLJS.lj,null,$CLJS.Xi,null],null),null),
FS=$CLJS.eu.g(DS,ES),jka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"date bucketing unit"],null)],null),DS),kka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"time bucketing unit"],null)],null),ES),GS=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"datetime bucketing unit"],null)],null),FS),HS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rJ],null),lka=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,
1,[$CLJS.vt,"temporal extract unit"],null),$CLJS.eH,$CLJS.Ci,$CLJS.qk,$CLJS.cJ,$CLJS.xJ,$CLJS.yJ,$CLJS.Jj,$CLJS.Ii,$CLJS.wi,$CLJS.lj,$CLJS.bH],null),mka=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"datetime-diff unit"],null),$CLJS.aH,$CLJS.Xi,$CLJS.uk,$CLJS.du,$CLJS.rj,$CLJS.Aj,$CLJS.pi,$CLJS.ji],null),IS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"temporal-extract week extraction mode"],null),$CLJS.XI,$CLJS.fJ,$CLJS.kJ],null),JS=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"relative-datetime unit"],null),$CLJS.ci,$CLJS.Xi,$CLJS.uk,$CLJS.du,$CLJS.rj,$CLJS.Aj,$CLJS.pi,$CLJS.ji],null),QS,oka,YS,ZS,$S,aT,bT,cT,dT,PU,qka,QU,rka,ska,RU,tka,uka,vka;$CLJS.X(ZM,iM($CLJS.gJ,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,$CLJS.Yv],null),$CLJS.yi],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,JS],null)])));var KS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZM],null);
$CLJS.X(DP,iM($CLJS.eJ,$CLJS.H(["n",$CLJS.yi,"unit",JS])));var nka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DP],null);
$CLJS.X(tN,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.vt,"valid :absolute-datetime clause",$CLJS.Ri,function(a){if($CLJS.Va(hM($CLJS.aJ,a)))a=sN;else{a=$CLJS.hd(a);var b=$CLJS.dM(AS);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.HP:$CLJS.hJ}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[sN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.vt,"not an :absolute-datetime clause"],null),$CLJS.Ue(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HP,iM($CLJS.aJ,
$CLJS.H(["date",AS,"unit",jka]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hJ,iM($CLJS.aJ,$CLJS.H(["datetime",BS,"unit",GS]))],null)],null));var LS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tN],null);$CLJS.X(IN,iM($CLJS.Ky,$CLJS.H(["time",CS,"unit",kka])));var MS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IN],null),NS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.vt,"date or datetime literal"],null),LS,BS,AS],null);
$CLJS.X(tM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.vt,"time literal"],null),MS,CS],null));$CLJS.X(kS,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.vt,"temporal literal"],null),NS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tM],null)],null));var OS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kS],null);
$CLJS.X(Gja,jM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof LS?new $CLJS.Gc(function(){return LS},$CLJS.od(fia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,CQ,$CLJS.qj,$CLJS.Ck,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),kQ,"metabase/mbql/schema.cljc",69,$CLJS.aJ,1,!0,168,168,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(LS)?LS.H:null])):null));return $CLJS.n(a)?a:kQ}(),LS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof KS?new $CLJS.Gc(function(){return KS},$CLJS.od(bka,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,
$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BP,"metabase/mbql/schema.cljc",29,$CLJS.gJ,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gJ,$CLJS.vk,$CLJS.U($CLJS.Tj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,$CLJS.Yv],null),$CLJS.yi],null)),$CLJS.si,$CLJS.U($CLJS.Tj,$CLJS.U(nR,ZO))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",
$CLJS.n(KS)?KS.H:null])):null));return $CLJS.n(a)?a:BP}(),KS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof MS?new $CLJS.Gc(function(){return MS},$CLJS.od(dja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,CQ,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bS,"metabase/mbql/schema.cljc",27,$CLJS.Ky,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ky,$CLJS.Ky,$CLJS.U($CLJS.Tj,Fja),$CLJS.si,$CLJS.U($CLJS.Tj,xia)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.n(MS)?MS.H:null])):null));return $CLJS.n(a)?a:bS}(),MS],null)])));
$CLJS.X($R,iM($CLJS.Gj,$CLJS.H(["value",$CLJS.li,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cS,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,uS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kD,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,vS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JO,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ys,wS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.si,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,GS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,uS],null)],null)],null)],null)])));var PS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$R],null);$CLJS.X(IO,iM($CLJS.tB,$CLJS.H(["expression-name",uS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,$CLJS.mk],null)])));
QS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IO],null);
oka=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.vt,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.JE);c=$CLJS.J.g(c,$CLJS.rE);return $CLJS.E.g(b,$CLJS.rE)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.vt,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.JE);c=$CLJS.J.g(c,$CLJS.IE);return $CLJS.E.g(b,$CLJS.IE)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.vt,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JE,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"binning strategy"],null),$CLJS.rE,$CLJS.IE,$CLJS.ci],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),xS],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.IE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,$CLJS.Fl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.vt,"bin width must be \x3e\x3d 0."],null),$CLJS.Te($CLJS.$l)],null)],null)],null)],null)));$CLJS.RS=function RS(a){switch(arguments.length){case 1:return RS.h(arguments[0]);case 2:return RS.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.RS.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.wB);b=$CLJS.J.g(b,$CLJS.uG);return $CLJS.RS.g(a,b)};$CLJS.RS.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.xC(a,$CLJS.YC)?DS:$CLJS.xC(a,$CLJS.XD)?ES:$CLJS.xC(a,$CLJS.xD)?FS:null:null;return $CLJS.n(a)?$CLJS.Hd(a,b):!0};$CLJS.RS.A=2;
$CLJS.X(oR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.vt,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wB,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,vS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KN,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,zS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,new $CLJS.h(null,
1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,GS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zP,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,uS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AR,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,oka],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.vt,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.RS],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.vt,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Te($CLJS.JE)],null)],null));
$CLJS.X(QR,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.vt,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.wB);return"string"===typeof c?b:!0}],null)],null)}(iM($CLJS.oG,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,zS,uS],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oR],null)],null)]))));var SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QR],null);
$CLJS.X(QP,jM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof QS?new $CLJS.Gc(function(){return QS},$CLJS.od(UN,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EN,"metabase/mbql/schema.cljc",60,$CLJS.tB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.tB,$CLJS.gS,$CLJS.U($CLJS.Tj,aN),$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(nR,$CLJS.mk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(QS)?QS.H:null])):null));return $CLJS.n(a)?a:EN}(),QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof SS?new $CLJS.Gc(function(){return SS},
$CLJS.od(lO,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.zr,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,$CLJS.ui,$CLJS.ok,$CLJS.sk],["0.39.0",$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mN,"metabase/mbql/schema.cljc",51,$CLJS.oG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(SS)?SS.H:null])):null));return $CLJS.n(a)?a:mN}(),SS],null)])));
$CLJS.TS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QP],null);$CLJS.X(oM,iM($CLJS.dG,$CLJS.H(["aggregation-clause-index",$CLJS.yi,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,$CLJS.mk],null)])));var US=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oM],null);
$CLJS.X(VM,jM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof US?new $CLJS.Gc(function(){return US},$CLJS.od(Dja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),zR,"metabase/mbql/schema.cljc",23,$CLJS.dG,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.dG,dka,$CLJS.U($CLJS.Tj,$CLJS.yi),$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(nR,$CLJS.mk))],null),$CLJS.Kc,"Schema for a valid aggregation clause.",$CLJS.n(US)?US.H:null])):null));return $CLJS.n(a)?a:zR}(),US],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof QS?new $CLJS.Gc(function(){return QS},
$CLJS.od(UN,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EN,"metabase/mbql/schema.cljc",60,$CLJS.tB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.tB,$CLJS.gS,$CLJS.U($CLJS.Tj,aN),
$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(nR,$CLJS.mk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(QS)?QS.H:null])):null));return $CLJS.n(a)?a:EN}(),QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof SS?new $CLJS.Gc(function(){return SS},$CLJS.od(lO,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.zr,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,
$CLJS.ui,$CLJS.ok,$CLJS.sk],["0.39.0",$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mN,"metabase/mbql/schema.cljc",51,$CLJS.oG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(SS)?SS.H:null])):null));return $CLJS.n(a)?a:mN}(),SS],null)])));
var VS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VM],null),WS=new $CLJS.ah(null,new $CLJS.h(null,11,[$CLJS.It,null,$CLJS.GG,null,$CLJS.HF,null,$CLJS.MG,null,$CLJS.OF,null,$CLJS.rG,null,$CLJS.YF,null,$CLJS.Ot,null,$CLJS.EG,null,$CLJS.qG,null,$CLJS.NG,null],null),null),XS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SM],null);
$CLJS.X(vM,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ri,function(a){return"string"===typeof a?$CLJS.ck:$CLJS.n(hM(WS,a))?OM:$CLJS.n(hM($CLJS.Gj,a))?$CLJS.Gj:$CLJS.Kt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ck,$CLJS.ck],null),new $CLJS.P(null,2,5,$CLJS.Q,[OM,XS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,$CLJS.TS],null)],null));YS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vM],null);
ZS=new $CLJS.ah(null,new $CLJS.h(null,26,[$CLJS.KF,null,$CLJS.wJ,null,$CLJS.gs,null,$CLJS.sJ,null,$CLJS.pJ,null,$CLJS.bG,null,$CLJS.ot,null,$CLJS.GF,null,$CLJS.tJ,null,$CLJS.mJ,null,$CLJS.rG,null,$CLJS.JG,null,$CLJS.qJ,null,$CLJS.tG,null,$CLJS.MF,null,$CLJS.ZI,null,$CLJS.fs,null,$CLJS.PF,null,$CLJS.YF,null,$CLJS.TI,null,$CLJS.Ux,null,$CLJS.$I,null,$CLJS.lJ,null,$CLJS.dJ,null,$CLJS.LG,null,$CLJS.mG,null],null),null);
$S=new $CLJS.ah(null,new $CLJS.h(null,9,[$CLJS.cs,null,$CLJS.Xr,null,$CLJS.zs,null,$CLJS.Bs,null,$CLJS.Zr,null,$CLJS.FG,null,$CLJS.Fs,null,$CLJS.ds,null,$CLJS.as,null],null),null);aT=new $CLJS.ah(null,new $CLJS.h(null,17,[$CLJS.co,null,$CLJS.DG,null,$CLJS.xG,null,$CLJS.pG,null,$CLJS.IG,null,$CLJS.KG,null,$CLJS.UF,null,$CLJS.$F,null,$CLJS.AM,null,$CLJS.VF,null,$CLJS.CG,null,$CLJS.vG,null,$CLJS.nk,null,$CLJS.Wx,null,$CLJS.iG,null,$CLJS.jG,null,$CLJS.BG,null],null),null);
bT=new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.iJ,null,$CLJS.VI,null,$CLJS.uJ,null,$CLJS.fs,null,$CLJS.oJ,null],null),null);cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gR],null);dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GN],null);$CLJS.eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IQ],null);$CLJS.fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mR],null);
$CLJS.X(tR,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.vt,"numeric expression argument",$CLJS.Ri,function(a){return"number"===typeof a?$CLJS.Xt:$CLJS.n(hM(ZS,a))?jP:$CLJS.n(hM(aT,a))?$CLJS.dG:$CLJS.n(hM($CLJS.Gj,a))?$CLJS.Gj:$CLJS.oG}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,$CLJS.Fl],null),new $CLJS.P(null,2,5,$CLJS.Q,[jP,cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dG,$CLJS.fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oG,
$CLJS.TS],null)],null));var gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tR],null);
$CLJS.X(DQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.vt,"datetime expression argument",$CLJS.Ri,function(a){return $CLJS.n(hM(aT,a))?$CLJS.dG:$CLJS.n(hM($CLJS.Gj,a))?$CLJS.Gj:$CLJS.n(hM(bT,a))?NN:$CLJS.Kt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dG,$CLJS.fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[NN,$CLJS.eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,NS,$CLJS.TS],null)],null)],null));
var hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DQ],null);
$CLJS.X(fQ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.vt,"expression argument",$CLJS.Ri,function(a){return"number"===typeof a?$CLJS.Xt:$CLJS.Cd(a)?$CLJS.Ms:$CLJS.n(hM($S,a))?vQ:$CLJS.n(hM(ZS,a))?jP:$CLJS.n(hM(bT,a))?NN:"string"===typeof a?$CLJS.ck:$CLJS.n(hM(WS,a))?OM:$CLJS.n(hM($CLJS.Gj,a))?$CLJS.Gj:$CLJS.Kt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,$CLJS.Fl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ms,$CLJS.Ms],null),new $CLJS.P(null,2,5,$CLJS.Q,[vQ,dT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[jP,cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[NN,$CLJS.eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ck,$CLJS.ck],null),new $CLJS.P(null,2,5,$CLJS.Q,[OM,XS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,$CLJS.TS],null)],null));var iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fQ],null);$CLJS.X(dQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.vt,"numeric expression arg or interval"],null),nka,gT],null));
var jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dQ],null);$CLJS.X(GM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.vt,"int greater than zero or numeric expression",$CLJS.Ri,function(a){return"number"===typeof a?$CLJS.Xt:$CLJS.Kt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,xS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,cT],null)],null));var pka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GM],null);
$CLJS.X(ON,iM($CLJS.rG,$CLJS.H(["a",iT,"b",iT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,iT],null)])));var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ON],null);$CLJS.X(sM,iM($CLJS.HF,$CLJS.H(["s",YS,"start",pka,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,gT],null)])));var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sM],null);$CLJS.X(wQ,iM($CLJS.Ux,$CLJS.H(["s",YS])));var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wQ],null);$CLJS.X(UM,iM($CLJS.EG,$CLJS.H(["s",YS])));
var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UM],null);$CLJS.X(YM,iM($CLJS.NG,$CLJS.H(["s",YS])));var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YM],null);$CLJS.X(qN,iM($CLJS.GG,$CLJS.H(["s",YS])));var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qN],null);$CLJS.X(bO,iM($CLJS.It,$CLJS.H(["s",YS])));var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bO],null);$CLJS.X(LM,iM($CLJS.Ot,$CLJS.H(["s",YS])));var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LM],null);
$CLJS.X(sR,iM($CLJS.MG,$CLJS.H(["s",YS,"match",$CLJS.ck,"replacement",$CLJS.ck])));var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sR],null);$CLJS.X(pS,iM($CLJS.OF,$CLJS.H(["a",YS,"b",YS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,YS],null)])));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pS],null);$CLJS.X(RO,iM($CLJS.qG,$CLJS.H(["s",YS,"pattern",$CLJS.ck])));var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RO],null);
$CLJS.X(OR,iM($CLJS.fs,$CLJS.H(["x",jT,"y",jT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,jT],null)])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OR],null);$CLJS.X(MR,iM($CLJS.ot,$CLJS.H(["x",gT,"y",jT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,jT],null)])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MR],null);$CLJS.X(LR,iM($CLJS.JG,$CLJS.H(["x",gT,"y",gT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,gT],null)])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LR],null);
$CLJS.X(NR,iM($CLJS.gs,$CLJS.H(["x",gT,"y",gT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,gT],null)])));var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NR],null);$CLJS.X(cQ,iM($CLJS.mG,$CLJS.H(["x",gT])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cQ],null);$CLJS.X(WQ,iM($CLJS.KF,$CLJS.H(["x",gT])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WQ],null);$CLJS.X(sS,iM($CLJS.tG,$CLJS.H(["x",gT])));var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sS],null);$CLJS.X(hP,iM($CLJS.PF,$CLJS.H(["x",gT])));
var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hP],null);$CLJS.X(nP,iM($CLJS.MF,$CLJS.H(["x",gT,"y",gT])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nP],null);$CLJS.X(KP,iM($CLJS.bG,$CLJS.H(["x",gT])));var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KP],null);$CLJS.X(HM,iM($CLJS.GF,$CLJS.H(["x",gT])));var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HM],null);$CLJS.X(iQ,iM($CLJS.LG,$CLJS.H(["x",gT])));var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iQ],null);
$CLJS.X(gN,iM($CLJS.dJ,$CLJS.H(["datetime-x",hT,"datetime-y",hT,"unit",mka])));var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gN],null);$CLJS.X(yN,iM($CLJS.sJ,$CLJS.H(["datetime",hT,"unit",lka,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,IS],null)])));var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yN],null);$CLJS.X(pP,iM($CLJS.$I,$CLJS.H(["date",hT])));var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pP],null);$CLJS.X(RP,iM($CLJS.wJ,$CLJS.H(["date",hT])));
var KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RP],null);$CLJS.X(uM,iM($CLJS.TI,$CLJS.H(["date",hT])));var LT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uM],null);$CLJS.X(qS,iM($CLJS.mJ,$CLJS.H(["date",hT,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,IS],null)])));var MT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qS],null);$CLJS.X(CO,iM($CLJS.lJ,$CLJS.H(["date",hT])));var NT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CO],null);$CLJS.X(LP,iM($CLJS.qJ,$CLJS.H(["date",hT])));
var OT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LP],null);$CLJS.X(cN,iM($CLJS.tJ,$CLJS.H(["datetime",hT])));var PT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cN],null);$CLJS.X(HO,iM($CLJS.pJ,$CLJS.H(["datetime",hT])));var QT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HO],null);$CLJS.X(WM,iM($CLJS.ZI,$CLJS.H(["datetime",hT])));var RT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WM],null);$CLJS.X(mO,iM($CLJS.VI,$CLJS.H(["datetime",hT,"to",HS,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,HS],null)])));
var ST=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mO],null),TT=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"datetime arithmetic unit"],null),$CLJS.dH,$CLJS.aH,$CLJS.Xi,$CLJS.uk,$CLJS.du,$CLJS.rj,$CLJS.Aj,$CLJS.pi,$CLJS.ji],null);$CLJS.X(rM,iM($CLJS.oJ,$CLJS.H(["datetime",hT,"amount",gT,"unit",TT])));var UT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rM],null);
$CLJS.X(PM,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return iM(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.iJ));var VT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PM],null);$CLJS.X(qM,iM($CLJS.uJ,$CLJS.H(["datetime",hT,"amount",gT,"unit",TT])));var WT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qM],null);
$CLJS.X(IQ,jM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof vT?new $CLJS.Gc(function(){return vT},$CLJS.od(XP,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),LO,"metabase/mbql/schema.cljc",51,$CLJS.fs,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.fs,$CLJS.GE,$CLJS.U($CLJS.Tj,aQ),yR,$CLJS.U($CLJS.Tj,aQ),PN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Pi,aQ))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:LO}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof UT?new $CLJS.Gc(function(){return UT},
$CLJS.od(Oia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[fO,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SP,"metabase/mbql/schema.cljc",67,$CLJS.oJ,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.oJ,$CLJS.hJ,$CLJS.U($CLJS.Tj,lS),SQ,$CLJS.U($CLJS.Tj,
XQ),$CLJS.si,$CLJS.U($CLJS.Tj,eR)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.n(UT)?UT.H:null])):null));return $CLJS.n(a)?a:SP}(),UT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof WT?new $CLJS.Gc(function(){return WT},$CLJS.od(Hha,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,
$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[fO,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jS,"metabase/mbql/schema.cljc",72,$CLJS.uJ,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uJ,$CLJS.hJ,$CLJS.U($CLJS.Tj,lS),SQ,$CLJS.U($CLJS.Tj,XQ),$CLJS.si,$CLJS.U($CLJS.Tj,eR)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.n(WT)?WT.H:null])):null));
return $CLJS.n(a)?a:jS}(),WT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof ST?new $CLJS.Gc(function(){return ST},$CLJS.od(Mja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.VI,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MN,"metabase/mbql/schema.cljc",71,$CLJS.VI,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.VI,$CLJS.hJ,$CLJS.U($CLJS.Tj,lS),Gia,$CLJS.U($CLJS.Tj,BR),Lha,$CLJS.U($CLJS.Tj,$CLJS.U(nR,BR))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.n(ST)?ST.H:null])):null));return $CLJS.n(a)?a:MN}(),ST],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&
"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof VT?new $CLJS.Gc(function(){return VT},$CLJS.od(oja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.iJ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qP,"metabase/mbql/schema.cljc",
45,$CLJS.iJ,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.iJ],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.n(VT)?VT.H:null])):null));return $CLJS.n(a)?a:qP}(),VT],null)])));$CLJS.XT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NP],null);$CLJS.X(wM,iM($CLJS.Fs,$CLJS.H(["first-clause",$CLJS.XT,"second-clause",$CLJS.XT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,$CLJS.XT],null)])));var YT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wM],null);
$CLJS.X(iO,iM($CLJS.zs,$CLJS.H(["first-clause",$CLJS.XT,"second-clause",$CLJS.XT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,$CLJS.XT],null)])));var ZT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iO],null);$CLJS.X(RM,iM($CLJS.Bs,$CLJS.H(["clause",$CLJS.XT])));
var $T=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RM],null),aU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,3,[$CLJS.vt,":field or :expression reference or :relative-datetime",$CLJS.xt,$CLJS.Ue(":field or :expression reference or :relative-datetime"),$CLJS.Ri,function(a){return $CLJS.n(hM($CLJS.gJ,a))?$CLJS.gJ:$CLJS.Kt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gJ,KS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,$CLJS.TS],null)],null);
$CLJS.X(FQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.vt,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.zs,$CLJS.Ms,$CLJS.Fl,$CLJS.ck,OS,aU,iT,PS],null)],null));var bU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FQ],null);
$CLJS.X(WO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.vt,"order comparable",$CLJS.Ri,function(a){return $CLJS.n(hM($CLJS.Gj,a))?$CLJS.Gj:$CLJS.Kt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.zs,$CLJS.Fl,$CLJS.ck,OS,iT,aU],null)],null)],null));var cU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WO],null);
$CLJS.X(ER,iM($CLJS.ds,$CLJS.H(["field",bU,"value-or-field",bU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,bU],null)])));var dU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ER],null);$CLJS.X(IR,iM($CLJS.FG,$CLJS.H(["field",bU,"value-or-field",bU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,bU],null)])));var eU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IR],null);$CLJS.X(DR,iM($CLJS.as,$CLJS.H(["field",cU,"value-or-field",cU])));
var fU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DR],null);$CLJS.X(CR,iM($CLJS.Xr,$CLJS.H(["field",cU,"value-or-field",cU])));var gU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CR],null);$CLJS.X(rN,iM($CLJS.cs,$CLJS.H(["field",cU,"value-or-field",cU])));var hU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rN],null);$CLJS.X(xN,iM($CLJS.Zr,$CLJS.H(["field",cU,"value-or-field",cU])));var iU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xN],null);$CLJS.X(nQ,iM($CLJS.RF,$CLJS.H(["field",cU,"min",cU,"max",cU])));
var jU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nQ],null);$CLJS.X(PQ,iM($CLJS.XF,$CLJS.H(["lat-field",cU,"lon-field",cU,"lat-max",cU,"lon-min",cU,"lat-min",cU,"lon-max",cU])));var kU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PQ],null);$CLJS.X(aO,iM($CLJS.QF,$CLJS.H(["field",$CLJS.TS])));var lU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aO],null);$CLJS.X(QO,iM($CLJS.aG,$CLJS.H(["field",$CLJS.TS])));var mU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QO],null);$CLJS.X(sO,iM($CLJS.SF,$CLJS.H(["field",$CLJS.TS])));
var nU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sO],null);$CLJS.X(zM,iM($CLJS.ZF,$CLJS.H(["field",$CLJS.TS])));var oU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zM],null),pU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ms],null)],null);$CLJS.X(FO,iM($CLJS.fG,$CLJS.H(["field",YS,"string-or-field",YS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,pU],null)])));var qU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FO],null);
$CLJS.X(yP,iM($CLJS.zG,$CLJS.H(["field",YS,"string-or-field",YS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,pU],null)])));var rU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yP],null);$CLJS.X(qO,iM($CLJS.nG,$CLJS.H(["field",YS,"string-or-field",YS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,pU],null)])));var sU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qO],null);$CLJS.X(gQ,iM($CLJS.gG,$CLJS.H(["field",YS,"string-or-field",YS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,pU],null)])));
var tU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gQ],null);$CLJS.X(IM,iM($CLJS.AG,$CLJS.H(["field",$CLJS.TS,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,$CLJS.yi,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,$CLJS.Yv,$CLJS.$x,$CLJS.WF],null)],null),"unit",JS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kk,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ms],null)],null)],null)])));var uU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IM],null);
$CLJS.X(yQ,iM($CLJS.NF,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WK],null),uS],null)])));var vU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yQ],null);
$CLJS.X(GN,jM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof YT?new $CLJS.Gc(function(){return YT},$CLJS.od(rR,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$O,"metabase/mbql/schema.cljc",15,$CLJS.Fs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Fs,QN,$CLJS.U($CLJS.Tj,eP),IP,$CLJS.U($CLJS.Tj,eP),$N,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Pi,eP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(YT)?YT.H:null])):null));return $CLJS.n(a)?a:$O}(),YT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof ZT?new $CLJS.Gc(function(){return ZT},
$CLJS.od(MP,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PP,"metabase/mbql/schema.cljc",14,$CLJS.zs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zs,QN,$CLJS.U($CLJS.Tj,eP),IP,$CLJS.U($CLJS.Tj,eP),$N,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Pi,eP))],null),$CLJS.Kc,
"Schema for a valid or clause.",$CLJS.n(ZT)?ZT.H:null])):null));return $CLJS.n(a)?a:PP}(),ZT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof $T?new $CLJS.Gc(function(){return $T},$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rP,"metabase/mbql/schema.cljc",15,$CLJS.Bs,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bs,$CLJS.fS,$CLJS.U($CLJS.Tj,eP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n($T)?$T.H:null])):null));return $CLJS.n(a)?a:rP}(),$T],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==
typeof fU?new $CLJS.Gc(function(){return fU},$CLJS.od(VP,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TO,"metabase/mbql/schema.cljc",13,$CLJS.as,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.as,$CLJS.oG,$CLJS.U($CLJS.Tj,SN),cR,$CLJS.U($CLJS.Tj,SN)],
null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:TO}(),fU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof hU?new $CLJS.Gc(function(){return hU},$CLJS.od(ZR,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,
1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",14,$CLJS.cs,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.cs,$CLJS.oG,$CLJS.U($CLJS.Tj,SN),cR,$CLJS.U($CLJS.Tj,SN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(hU)?hU.H:null])):null));return $CLJS.n(a)?a:SO}(),hU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&
"undefined"!==typeof nM&&"undefined"!==typeof gU?new $CLJS.Gc(function(){return gU},$CLJS.od(TP,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UO,"metabase/mbql/schema.cljc",13,$CLJS.Xr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Xr,$CLJS.oG,$CLJS.U($CLJS.Tj,
SN),cR,$CLJS.U($CLJS.Tj,SN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(gU)?gU.H:null])):null));return $CLJS.n(a)?a:UO}(),gU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof iU?new $CLJS.Gc(function(){return iU},$CLJS.od(RR,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],
[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aP,"metabase/mbql/schema.cljc",14,$CLJS.Zr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Zr,$CLJS.oG,$CLJS.U($CLJS.Tj,SN),cR,$CLJS.U($CLJS.Tj,SN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(iU)?iU.H:null])):null));return $CLJS.n(a)?a:aP}(),iU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&
"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.od(UP,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VO,"metabase/mbql/schema.cljc",13,$CLJS.ds,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.ds,$CLJS.oG,$CLJS.U($CLJS.Tj,dS),cR,$CLJS.U($CLJS.Tj,dS),GQ,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Pi,dS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(dU)?dU.H:null])):null));return $CLJS.n(a)?a:VO}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof eU?new $CLJS.Gc(function(){return eU},$CLJS.od(hN,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,
$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DN,"metabase/mbql/schema.cljc",14,$CLJS.FG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.FG,$CLJS.oG,$CLJS.U($CLJS.Tj,dS),cR,$CLJS.U($CLJS.Tj,dS),GQ,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Pi,dS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(eU)?eU.H:null])):null));
return $CLJS.n(a)?a:DN}(),eU],null)])));
$CLJS.X(NP,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.vt,"valid filter expression",$CLJS.Ri,function(a){return $CLJS.n(hM(bT,a))?$CLJS.hJ:$CLJS.n(hM(ZS,a))?mS:$CLJS.n(hM(WS,a))?$CLJS.ck:$CLJS.n(hM($S,a))?$CLJS.Ms:$CLJS.Kt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hJ,$CLJS.eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[mS,cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ck,XS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ms,dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,jM($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof YT?new $CLJS.Gc(function(){return YT},$CLJS.od(rR,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$O,"metabase/mbql/schema.cljc",
15,$CLJS.Fs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Fs,QN,$CLJS.U($CLJS.Tj,eP),IP,$CLJS.U($CLJS.Tj,eP),$N,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Pi,eP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(YT)?YT.H:null])):null));return $CLJS.n(a)?a:$O}(),YT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof ZT?new $CLJS.Gc(function(){return ZT},$CLJS.od(MP,new $CLJS.h(null,1,[$CLJS.ek,
!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PP,"metabase/mbql/schema.cljc",14,$CLJS.zs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zs,QN,$CLJS.U($CLJS.Tj,eP),IP,$CLJS.U($CLJS.Tj,eP),$N,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Pi,eP))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.n(ZT)?
ZT.H:null])):null));return $CLJS.n(a)?a:PP}(),ZT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof $T?new $CLJS.Gc(function(){return $T},$CLJS.od(zQ,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),rP,"metabase/mbql/schema.cljc",15,$CLJS.Bs,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bs,$CLJS.fS,$CLJS.U($CLJS.Tj,eP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n($T)?$T.H:null])):null));return $CLJS.n(a)?a:rP}(),$T],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.od(UP,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,
$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VO,"metabase/mbql/schema.cljc",13,$CLJS.ds,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ds,$CLJS.oG,$CLJS.U($CLJS.Tj,dS),cR,$CLJS.U($CLJS.Tj,dS),GQ,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Pi,dS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(dU)?dU.H:null])):
null));return $CLJS.n(a)?a:VO}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof eU?new $CLJS.Gc(function(){return eU},$CLJS.od(hN,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DN,"metabase/mbql/schema.cljc",14,$CLJS.FG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.FG,$CLJS.oG,$CLJS.U($CLJS.Tj,dS),cR,$CLJS.U($CLJS.Tj,dS),GQ,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Pi,dS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(eU)?eU.H:null])):null));return $CLJS.n(a)?a:DN}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof fU?new $CLJS.Gc(function(){return fU},
$CLJS.od(VP,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TO,"metabase/mbql/schema.cljc",13,$CLJS.as,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.as,$CLJS.oG,$CLJS.U($CLJS.Tj,SN),cR,$CLJS.U($CLJS.Tj,SN)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",
$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:TO}(),fU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof gU?new $CLJS.Gc(function(){return gU},$CLJS.od(TP,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),UO,"metabase/mbql/schema.cljc",13,$CLJS.Xr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Xr,$CLJS.oG,$CLJS.U($CLJS.Tj,SN),cR,$CLJS.U($CLJS.Tj,SN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(gU)?gU.H:null])):null));return $CLJS.n(a)?a:UO}(),gU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof hU?new $CLJS.Gc(function(){return hU},$CLJS.od(ZR,new $CLJS.h(null,
1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",14,$CLJS.cs,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.cs,$CLJS.oG,$CLJS.U($CLJS.Tj,SN),cR,$CLJS.U($CLJS.Tj,SN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(hU)?hU.H:null])):
null));return $CLJS.n(a)?a:SO}(),hU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof iU?new $CLJS.Gc(function(){return iU},$CLJS.od(RR,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aP,"metabase/mbql/schema.cljc",14,$CLJS.Zr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Zr,$CLJS.oG,$CLJS.U($CLJS.Tj,SN),cR,$CLJS.U($CLJS.Tj,SN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(iU)?iU.H:null])):null));return $CLJS.n(a)?a:aP}(),iU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.od(fja,new $CLJS.h(null,
1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jN,"metabase/mbql/schema.cljc",19,$CLJS.RF,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.RF,$CLJS.oG,$CLJS.U($CLJS.Tj,SN),$CLJS.co,$CLJS.U($CLJS.Tj,SN),$CLJS.nk,$CLJS.U($CLJS.Tj,SN)],null),$CLJS.Kc,"Schema for a valid between clause.",
$CLJS.n(jU)?jU.H:null])):null));return $CLJS.n(a)?a:jN}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof qU?new $CLJS.Gc(function(){return qU},$CLJS.od(hja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hR,"metabase/mbql/schema.cljc",23,$CLJS.fG,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.fG,$CLJS.oG,$CLJS.U($CLJS.Tj,bR),CN,$CLJS.U($CLJS.Tj,bR),$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(nR,xQ))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.n(qU)?qU.H:null])):null));return $CLJS.n(a)?a:hR}(),qU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof rU?new $CLJS.Gc(function(){return rU},
$CLJS.od(iia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HN,"metabase/mbql/schema.cljc",21,$CLJS.zG,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zG,$CLJS.oG,$CLJS.U($CLJS.Tj,bR),CN,$CLJS.U($CLJS.Tj,bR),$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(nR,xQ))],null),
$CLJS.Kc,"Schema for a valid ends-with clause.",$CLJS.n(rU)?rU.H:null])):null));return $CLJS.n(a)?a:HN}(),rU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof sU?new $CLJS.Gc(function(){return sU},$CLJS.od(via,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,
1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TN,"metabase/mbql/schema.cljc",20,$CLJS.nG,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.nG,$CLJS.oG,$CLJS.U($CLJS.Tj,bR),CN,$CLJS.U($CLJS.Tj,bR),$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(nR,xQ))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.n(sU)?sU.H:null])):null));return $CLJS.n(a)?a:TN}(),sU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==
typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof tU?new $CLJS.Gc(function(){return tU},$CLJS.od($ja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,pR,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xM,"metabase/mbql/schema.cljc",36,$CLJS.gG,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.gG,$CLJS.oG,$CLJS.U($CLJS.Tj,bR),CN,$CLJS.U($CLJS.Tj,bR),$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(nR,xQ))],null),$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.n(tU)?tU.H:null])):null));return $CLJS.n(a)?a:xM}(),tU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof kU?new $CLJS.Gc(function(){return kU},$CLJS.od(Jja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),
$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,pR,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fR,"metabase/mbql/schema.cljc",26,$CLJS.XF,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.XF,Rha,$CLJS.U($CLJS.Tj,SN),eka,$CLJS.U($CLJS.Tj,SN),Qja,$CLJS.U($CLJS.Tj,SN),Uha,$CLJS.U($CLJS.Tj,SN),Kja,$CLJS.U($CLJS.Tj,SN),Qha,$CLJS.U($CLJS.Tj,
SN)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:fR}(),kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof nU?new $CLJS.Gc(function(){return nU},$CLJS.od(wia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,pR,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,
1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",28,$CLJS.SF,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SF,$CLJS.oG,$CLJS.U($CLJS.Tj,LN)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.n(nU)?nU.H:null])):null));return $CLJS.n(a)?a:AP}(),nU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&
"undefined"!==typeof oU?new $CLJS.Gc(function(){return oU},$CLJS.od(uja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,pR,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oQ,"metabase/mbql/schema.cljc",29,$CLJS.ZF,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZF,$CLJS.oG,$CLJS.U($CLJS.Tj,LN)],null),
$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.n(oU)?oU.H:null])):null));return $CLJS.n(a)?a:oQ}(),oU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof lU?new $CLJS.Gc(function(){return lU},$CLJS.od(Vja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,pR,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,
1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZN,"metabase/mbql/schema.cljc",27,$CLJS.QF,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QF,$CLJS.oG,$CLJS.U($CLJS.Tj,LN)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.n(lU)?lU.H:null])):null));return $CLJS.n(a)?a:ZN}(),lU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&
"undefined"!==typeof mU?new $CLJS.Gc(function(){return mU},$CLJS.od(Iia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,pR,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oN,"metabase/mbql/schema.cljc",28,$CLJS.aG,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aG,$CLJS.oG,$CLJS.U($CLJS.Tj,LN)],null),
$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.n(mU)?mU.H:null])):null));return $CLJS.n(a)?a:oN}(),mU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof uU?new $CLJS.Gc(function(){return uU},$CLJS.od(Iha,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,pR,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,
1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NQ,"metabase/mbql/schema.cljc",33,$CLJS.AG,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.AG,$CLJS.oG,$CLJS.U($CLJS.Tj,LN),$CLJS.vk,$CLJS.U($CLJS.Tj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,$CLJS.yi,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,$CLJS.Yv,$CLJS.$x,$CLJS.WF],null)],null)),$CLJS.si,$CLJS.U($CLJS.Tj,ZO),$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(nR,kja))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",
$CLJS.n(uU)?uU.H:null])):null));return $CLJS.n(a)?a:NQ}(),uU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof vU?new $CLJS.Gc(function(){return vU},$CLJS.od(Qia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,pR,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),PR,"metabase/mbql/schema.cljc",27,$CLJS.NF,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NF,Yha,$CLJS.U($CLJS.Tj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,Aja,aN],null))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.n(vU)?vU.H:null])):null));return $CLJS.n(a)?a:PR}(),vU],null)]))],null)],null));
$CLJS.X(AQ,iM($CLJS.YF,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.vt,":case subclause"],null),$CLJS.XT,iT],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.vt,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),iT],null)],null)],null)])));
var wU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AQ],null);
$CLJS.X(gR,jM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof vT?new $CLJS.Gc(function(){return vT},$CLJS.od(XP,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),LO,"metabase/mbql/schema.cljc",51,$CLJS.fs,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.fs,$CLJS.GE,$CLJS.U($CLJS.Tj,aQ),yR,$CLJS.U($CLJS.Tj,aQ),PN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Pi,aQ))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:LO}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof wT?new $CLJS.Gc(function(){return wT},
$CLJS.od(bja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MO,"metabase/mbql/schema.cljc",51,$CLJS.ot,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ot,$CLJS.GE,$CLJS.U($CLJS.Tj,XQ),
yR,$CLJS.U($CLJS.Tj,aQ),PN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Pi,aQ))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:MO}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM?new $CLJS.Gc(function(){return xT},$CLJS.od(aja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,oP,$CLJS.Wi,$CLJS.qj,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],
[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.iA,"metabase/mbql/schema.cljc",$CLJS.JG,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.JG,$CLJS.GE,$CLJS.U($CLJS.Tj,XQ),yR,$CLJS.U($CLJS.Tj,XQ),PN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Pi,XQ))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?
a:$CLJS.iA}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof yT?new $CLJS.Gc(function(){return yT},$CLJS.od(cja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KO,"metabase/mbql/schema.cljc",51,$CLJS.gs,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gs,$CLJS.GE,$CLJS.U($CLJS.Tj,XQ),yR,$CLJS.U($CLJS.Tj,XQ),PN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Pi,XQ))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:KO}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof kT?new $CLJS.Gc(function(){return kT},
$CLJS.od(KQ,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZQ,"metabase/mbql/schema.cljc",58,$CLJS.rG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rG,HR,$CLJS.U($CLJS.Tj,CM),GR,$CLJS.U($CLJS.Tj,
CM),PN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Pi,CM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(kT)?kT.H:null])):null));return $CLJS.n(a)?a:ZQ}(),kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof mT?new $CLJS.Gc(function(){return mT},$CLJS.od(tja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,
$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",56,$CLJS.Ux,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ux,$CLJS.qy,$CLJS.U($CLJS.Tj,bR)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:rQ}(),mT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof zT?new $CLJS.Gc(function(){return zT},$CLJS.od(jia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),lP,"metabase/mbql/schema.cljc",55,$CLJS.mG,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,$CLJS.GE,$CLJS.U($CLJS.Tj,XQ)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:lP}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof AT?new $CLJS.Gc(function(){return AT},$CLJS.od(dia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),
$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$M,"metabase/mbql/schema.cljc",54,$CLJS.KF,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KF,$CLJS.GE,$CLJS.U($CLJS.Tj,XQ)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.n(AT)?
AT.H:null])):null));return $CLJS.n(a)?a:$M}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof BT?new $CLJS.Gc(function(){return BT},$CLJS.od(sia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,
1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bP,"metabase/mbql/schema.cljc",55,$CLJS.tG,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,$CLJS.GE,$CLJS.U($CLJS.Tj,XQ)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:bP}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&
"undefined"!==typeof CT?new $CLJS.Gc(function(){return CT},$CLJS.od(aka,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EP,"metabase/mbql/schema.cljc",53,$CLJS.PF,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.PF,$CLJS.GE,$CLJS.U($CLJS.Tj,XQ)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:EP}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof DT?new $CLJS.Gc(function(){return DT},$CLJS.od(Oja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,
kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[VR,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qR,"metabase/mbql/schema.cljc",69,$CLJS.MF,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.MF,$CLJS.GE,$CLJS.U($CLJS.Tj,XQ),yR,$CLJS.U($CLJS.Tj,XQ)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:qR}(),DT],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof ET?new $CLJS.Gc(function(){return ET},$CLJS.od(Lia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[VR,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),GO,"metabase/mbql/schema.cljc",68,$CLJS.bG,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bG,$CLJS.GE,$CLJS.U($CLJS.Tj,XQ)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:GO}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof FT?new $CLJS.Gc(function(){return FT},$CLJS.od(Pja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),
$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[VR,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dP,"metabase/mbql/schema.cljc",67,$CLJS.GF,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GF,$CLJS.GE,$CLJS.U($CLJS.Tj,XQ)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.n(FT)?
FT.H:null])):null));return $CLJS.n(a)?a:dP}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof GT?new $CLJS.Gc(function(){return GT},$CLJS.od(Xha,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[VR,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,
1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aS,"metabase/mbql/schema.cljc",67,$CLJS.LG,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LG,$CLJS.GE,$CLJS.U($CLJS.Tj,XQ)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.n(GT)?GT.H:null])):null));return $CLJS.n(a)?a:aS}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&
"undefined"!==typeof wU?new $CLJS.Gc(function(){return wU},$CLJS.od(NM,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.JF,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vP,"metabase/mbql/schema.cljc",61,$CLJS.YF,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.YF,$CLJS.gx,$CLJS.U($CLJS.Tj,eO),$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(nR,OP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(wU)?wU.H:null])):null));return $CLJS.n(a)?a:vP}(),wU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof HT?new $CLJS.Gc(function(){return HT},$CLJS.od($ha,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,
$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dJ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FP,"metabase/mbql/schema.cljc",65,$CLJS.dJ,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dJ,mia,$CLJS.U($CLJS.Tj,lS),nia,$CLJS.U($CLJS.Tj,lS),$CLJS.si,$CLJS.U($CLJS.Tj,Tia)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",
$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:FP}(),HT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof IT?new $CLJS.Gc(function(){return IT},$CLJS.od(xja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(VQ,
new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YP,"metabase/mbql/schema.cljc",71,$CLJS.sJ,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.sJ,$CLJS.hJ,$CLJS.U($CLJS.Tj,lS),$CLJS.si,$CLJS.U($CLJS.Tj,Uja),$CLJS.Ai,$CLJS.U($CLJS.Tj,$CLJS.U(nR,tS))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:YP}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof JT?new $CLJS.Gc(function(){return JT},$CLJS.od(Eia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,pR,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wN,"metabase/mbql/schema.cljc",71,$CLJS.$I,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$I,$CLJS.HP,$CLJS.U($CLJS.Tj,lS)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.n(JT)?JT.H:null])):null));return $CLJS.n(a)?a:wN}(),JT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof KT?new $CLJS.Gc(function(){return KT},$CLJS.od(tia,new $CLJS.h(null,1,[$CLJS.ek,!0],
null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,pR,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WP,"metabase/mbql/schema.cljc",74,$CLJS.wJ,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wJ,$CLJS.HP,$CLJS.U($CLJS.Tj,lS)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",
$CLJS.n(KT)?KT.H:null])):null));return $CLJS.n(a)?a:WP}(),KT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof LT?new $CLJS.Gc(function(){return LT},$CLJS.od($ia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,pR,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),
$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tQ,"metabase/mbql/schema.cljc",72,$CLJS.TI,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TI,$CLJS.HP,$CLJS.U($CLJS.Tj,lS)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.n(LT)?LT.H:null])):null));return $CLJS.n(a)?a:tQ}(),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==
typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof MT?new $CLJS.Gc(function(){return MT},$CLJS.od(Tja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,pR,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sQ,"metabase/mbql/schema.cljc",
71,$CLJS.mJ,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mJ,$CLJS.HP,$CLJS.U($CLJS.Tj,lS),$CLJS.Ai,$CLJS.U($CLJS.Tj,$CLJS.U(nR,tS))],null),$CLJS.Kc,"Schema for a valid get-week clause.",$CLJS.n(MT)?MT.H:null])):null));return $CLJS.n(a)?a:sQ}(),MT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof NT?new $CLJS.Gc(function(){return NT},$CLJS.od(Oha,new $CLJS.h(null,1,[$CLJS.ek,
!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,pR,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lQ,"metabase/mbql/schema.cljc",70,$CLJS.lJ,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lJ,$CLJS.HP,$CLJS.U($CLJS.Tj,lS)],null),$CLJS.Kc,"Schema for a valid get-day clause.",
$CLJS.n(NT)?NT.H:null])):null));return $CLJS.n(a)?a:lQ}(),NT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof OT?new $CLJS.Gc(function(){return OT},$CLJS.od(Uia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,pR,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),
$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bQ,"metabase/mbql/schema.cljc",78,$CLJS.qJ,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qJ,$CLJS.HP,$CLJS.U($CLJS.Tj,lS)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.n(OT)?OT.H:null])):null));return $CLJS.n(a)?a:bQ}(),OT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==
typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof PT?new $CLJS.Gc(function(){return PT},$CLJS.od(Sia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,pR,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DM,"metabase/mbql/schema.cljc",
71,$CLJS.tJ,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tJ,$CLJS.hJ,$CLJS.U($CLJS.Tj,lS)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.n(PT)?PT.H:null])):null));return $CLJS.n(a)?a:DM}(),PT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof QT?new $CLJS.Gc(function(){return QT},$CLJS.od(Bja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,
$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,pR,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eS,"metabase/mbql/schema.cljc",73,$CLJS.pJ,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pJ,$CLJS.hJ,$CLJS.U($CLJS.Tj,lS)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.n(QT)?QT.H:null])):null));
return $CLJS.n(a)?a:eS}(),QT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof RT?new $CLJS.Gc(function(){return RT},$CLJS.od(Pia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,pR,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.sJ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,
[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xP,"metabase/mbql/schema.cljc",73,$CLJS.ZI,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZI,$CLJS.hJ,$CLJS.U($CLJS.Tj,lS)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.n(RT)?RT.H:null])):null));return $CLJS.n(a)?a:xP}(),RT],null)])));
$CLJS.X(SM,jM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof lT?new $CLJS.Gc(function(){return lT},$CLJS.od(rja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BQ,"metabase/mbql/schema.cljc",59,$CLJS.HF,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.HF,$CLJS.qy,$CLJS.U($CLJS.Tj,bR),$CLJS.Dv,$CLJS.U($CLJS.Tj,pia),$CLJS.Ux,$CLJS.U($CLJS.Tj,$CLJS.U(nR,XQ))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:BQ}(),lT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof nT?new $CLJS.Gc(function(){return nT},
$CLJS.od(Rja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YQ,"metabase/mbql/schema.cljc",54,$CLJS.EG,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EG,$CLJS.qy,$CLJS.U($CLJS.Tj,bR)],
null),$CLJS.Kc,"Schema for a valid trim clause.",$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:YQ}(),nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof pT?new $CLJS.Gc(function(){return pT},$CLJS.od(Via,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,
1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HQ,"metabase/mbql/schema.cljc",55,$CLJS.GG,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GG,$CLJS.qy,$CLJS.U($CLJS.Tj,bR)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:HQ}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&
"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof oT?new $CLJS.Gc(function(){return oT},$CLJS.od(sja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JP,"metabase/mbql/schema.cljc",
55,$CLJS.NG,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NG,$CLJS.qy,$CLJS.U($CLJS.Tj,bR)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:JP}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof sT?new $CLJS.Gc(function(){return sT},$CLJS.od(Jha,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,
$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JR,"metabase/mbql/schema.cljc",57,$CLJS.MG,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.MG,$CLJS.qy,$CLJS.U($CLJS.Tj,bR),Vha,$CLJS.U($CLJS.Tj,$CLJS.ck),$CLJS.pA,$CLJS.U($CLJS.Tj,$CLJS.ck)],null),$CLJS.Kc,"Schema for a valid replace clause.",
$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:JR}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof rT?new $CLJS.Gc(function(){return rT},$CLJS.od(vja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,
new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oS,"metabase/mbql/schema.cljc",55,$CLJS.Ot,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ot,$CLJS.qy,$CLJS.U($CLJS.Tj,bR)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:oS}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==
typeof nM&&"undefined"!==typeof qT?new $CLJS.Gc(function(){return qT},$CLJS.od(Sja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kR,"metabase/mbql/schema.cljc",55,$CLJS.It,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.It,$CLJS.qy,$CLJS.U($CLJS.Tj,bR)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:kR}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof tT?new $CLJS.Gc(function(){return tT},$CLJS.od(jja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,
$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xO,"metabase/mbql/schema.cljc",56,$CLJS.OF,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.OF,HR,$CLJS.U($CLJS.Tj,bR),GR,$CLJS.U($CLJS.Tj,bR),PN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Pi,bR))],null),$CLJS.Kc,"Schema for a valid concat clause.",
$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:xO}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof uT?new $CLJS.Gc(function(){return uT},$CLJS.od(qja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.eQ,"null",$CLJS.Pda,"null"],
null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uN,"metabase/mbql/schema.cljc",74,$CLJS.qG,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qG,$CLJS.qy,$CLJS.U($CLJS.Tj,bR),$CLJS.oA,$CLJS.U($CLJS.Tj,$CLJS.ck)],null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:uN}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==
typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof kT?new $CLJS.Gc(function(){return kT},$CLJS.od(KQ,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.eQ,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
ZQ,"metabase/mbql/schema.cljc",58,$CLJS.rG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rG,HR,$CLJS.U($CLJS.Tj,CM),GR,$CLJS.U($CLJS.Tj,CM),PN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Pi,CM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(kT)?kT.H:null])):null));return $CLJS.n(a)?a:ZQ}(),kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof wU?new $CLJS.Gc(function(){return wU},
$CLJS.od(NM,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.JF,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vP,"metabase/mbql/schema.cljc",61,$CLJS.YF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.YF,$CLJS.gx,$CLJS.U($CLJS.Tj,eO),
$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(nR,OP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(wU)?wU.H:null])):null));return $CLJS.n(a)?a:vP}(),wU],null)])));
$CLJS.xU=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.vt,":field or :expression reference or expression",$CLJS.Ri,function(a){return $CLJS.n(hM(ZS,a))?mS:$CLJS.n(hM(WS,a))?$CLJS.ck:$CLJS.n(hM($S,a))?$CLJS.Ms:$CLJS.n(hM(bT,a))?$CLJS.hJ:$CLJS.n(hM($CLJS.YF,a))?$CLJS.YF:$CLJS.Kt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[mS,cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ck,XS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ms,dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hJ,$CLJS.eT],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,wU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,$CLJS.TS],null)],null);$CLJS.X(SR,iM($CLJS.Wx,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,$CLJS.TS],null)])));var yU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SR],null);$CLJS.X(GP,iM($CLJS.pG,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,$CLJS.TS],null)])));var zU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GP],null);$CLJS.X(QM,iM($CLJS.iG,$CLJS.H(["field-or-expression",$CLJS.xU])));
var AU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QM],null);$CLJS.X(KR,iM($CLJS.KG,$CLJS.H(["field-or-expression",$CLJS.xU])));var BU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KR],null);$CLJS.X(pO,iM($CLJS.UF,$CLJS.H(["field-or-expression",$CLJS.xU])));var CU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pO],null);$CLJS.X($Q,iM($CLJS.BG,$CLJS.H(["field-or-expression",$CLJS.xU])));var DU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$Q],null);$CLJS.X(kO,iM($CLJS.co,$CLJS.H(["field-or-expression",$CLJS.xU])));
var EU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kO],null);$CLJS.X(XN,iM($CLJS.nk,$CLJS.H(["field-or-expression",$CLJS.xU])));var FU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XN],null);$CLJS.X(RN,iM($CLJS.IG,$CLJS.H(["field-or-expression",$CLJS.xU,"pred",$CLJS.XT])));var GU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RN],null);$CLJS.X(hO,iM($CLJS.xG,$CLJS.H(["pred",$CLJS.XT])));var HU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hO],null);$CLJS.X(RQ,iM($CLJS.vG,$CLJS.H(["pred",$CLJS.XT])));
var IU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RQ],null);$CLJS.X(nN,iM($CLJS.DG,$CLJS.H(["field-or-expression",$CLJS.xU])));var JU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nN],null);$CLJS.X(pM,iM($CLJS.VF,$CLJS.H(["field-or-expression",$CLJS.xU])));var KU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pM],null);$CLJS.X(tO,iM($CLJS.CG,$CLJS.H(["field-or-expression",$CLJS.xU])));var LU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tO],null);$CLJS.X(gP,iM($CLJS.$F,$CLJS.H(["field-or-expression",$CLJS.xU,"percentile",gT])));
var MU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gP],null);$CLJS.X(UQ,iM($CLJS.jG,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UK],null),uS],null)])));var NU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UQ],null);
$CLJS.X(QQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.vt,"unnamed aggregation clause or numeric expression",$CLJS.Ri,function(a){return $CLJS.n(hM(ZS,a))?jP:$CLJS.Kt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[jP,cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kt,jM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof AU?new $CLJS.Gc(function(){return AU},$CLJS.od(pja,
new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.JF,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sP,"metabase/mbql/schema.cljc",60,$CLJS.iG,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,lR,$CLJS.U($CLJS.Tj,dR)],null),$CLJS.Kc,"Schema for a valid avg clause.",
$CLJS.n(AU)?AU.H:null])):null));return $CLJS.n(a)?a:sP}(),AU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof BU?new $CLJS.Gc(function(){return BU},$CLJS.od(Aia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.JF,"null"],null),null),$CLJS.od(VQ,
new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xR,"metabase/mbql/schema.cljc",64,$CLJS.KG,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KG,lR,$CLJS.U($CLJS.Tj,dR)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.n(BU)?BU.H:null])):null));return $CLJS.n(a)?a:xR}(),BU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==
typeof nM&&"undefined"!==typeof CU?new $CLJS.Gc(function(){return CU},$CLJS.od(ria,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.JF,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wP,"metabase/mbql/schema.cljc",65,$CLJS.UF,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UF,lR,$CLJS.U($CLJS.Tj,dR)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.n(CU)?CU.H:null])):null));return $CLJS.n(a)?a:wP}(),CU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof JU?new $CLJS.Gc(function(){return JU},$CLJS.od(Lja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,
$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.yG,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KM,"metabase/mbql/schema.cljc",76,$CLJS.DG,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DG,lR,$CLJS.U($CLJS.Tj,dR)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.n(JU)?JU.H:null])):null));return $CLJS.n(a)?a:KM}(),JU],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof DU?new $CLJS.Gc(function(){return DU},$CLJS.od(eja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.JF,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),fN,"metabase/mbql/schema.cljc",60,$CLJS.BG,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BG,lR,$CLJS.U($CLJS.Tj,dR)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.n(DU)?DU.H:null])):null));return $CLJS.n(a)?a:fN}(),DU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof EU?new $CLJS.Gc(function(){return EU},$CLJS.od(Sha,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,
$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.JF,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.bt,"metabase/mbql/schema.cljc",60,$CLJS.co,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.co,lR,$CLJS.U($CLJS.Tj,dR)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.n(EU)?EU.H:
null])):null));return $CLJS.n(a)?a:$CLJS.bt}(),EU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof FU?new $CLJS.Gc(function(){return FU},$CLJS.od(Nha,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.JF,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,
1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.ft,"metabase/mbql/schema.cljc",60,$CLJS.nk,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nk,lR,$CLJS.U($CLJS.Tj,dR)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.n(FU)?FU.H:null])):null));return $CLJS.n(a)?a:$CLJS.ft}(),FU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&
"undefined"!==typeof NU?new $CLJS.Gc(function(){return NU},$CLJS.od(oia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wO,"metabase/mbql/schema.cljc",18,$CLJS.jG,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,Bia,$CLJS.U($CLJS.Tj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.zs,Fia,aN],null))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.n(NU)?NU.H:null])):null));return $CLJS.n(a)?a:wO}(),NU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof IU?new $CLJS.Gc(function(){return IU},$CLJS.od(Nia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,
$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.JF,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oO,"metabase/mbql/schema.cljc",62,$CLJS.vG,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,$CLJS.vo,$CLJS.U($CLJS.Tj,eP)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.n(IU)?IU.H:null])):null));return $CLJS.n(a)?a:oO}(),IU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof HU?new $CLJS.Gc(function(){return HU},$CLJS.od(Tha,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.JF,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bN,"metabase/mbql/schema.cljc",68,$CLJS.xG,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xG,$CLJS.vo,$CLJS.U($CLJS.Tj,eP)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.n(HU)?HU.H:null])):null));return $CLJS.n(a)?a:bN}(),HU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof GU?new $CLJS.Gc(function(){return GU},$CLJS.od(Zja,new $CLJS.h(null,1,[$CLJS.ek,!0],
null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.JF,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tP,"metabase/mbql/schema.cljc",66,$CLJS.IG,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.IG,lR,$CLJS.U($CLJS.Tj,dR),$CLJS.vo,$CLJS.U($CLJS.Tj,eP)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",
$CLJS.n(GU)?GU.H:null])):null));return $CLJS.n(a)?a:tP}(),GU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof wU?new $CLJS.Gc(function(){return wU},$CLJS.od(NM,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.JF,"null"],null),null),$CLJS.od(VQ,
new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vP,"metabase/mbql/schema.cljc",61,$CLJS.YF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.YF,$CLJS.gx,$CLJS.U($CLJS.Tj,eO),$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(nR,OP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(wU)?wU.H:null])):null));return $CLJS.n(a)?a:vP}(),wU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&
"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof LU?new $CLJS.Gc(function(){return LU},$CLJS.od(Eja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kG,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vR,"metabase/mbql/schema.cljc",
68,$CLJS.CG,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CG,lR,$CLJS.U($CLJS.Tj,dR)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.n(LU)?LU.H:null])):null));return $CLJS.n(a)?a:vR}(),LU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof MU?new $CLJS.Gc(function(){return MU},$CLJS.od(Zia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,
oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kG,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TQ,"metabase/mbql/schema.cljc",72,$CLJS.$F,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$F,lR,$CLJS.U($CLJS.Tj,dR),$CLJS.$F,$CLJS.U($CLJS.Tj,XQ)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.n(MU)?MU.H:null])):
null));return $CLJS.n(a)?a:TQ}(),MU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof KU?new $CLJS.Gc(function(){return KU},$CLJS.od(cia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FM,"metabase/mbql/schema.cljc",77,$CLJS.VF,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VF,lR,$CLJS.U($CLJS.Tj,dR)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.n(KU)?KU.H:null])):null));return $CLJS.n(a)?a:FM}(),KU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof zU?new $CLJS.Gc(function(){return zU},$CLJS.od(Pha,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,
$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,pR,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.JF,"null"],null),null),$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fP,"metabase/mbql/schema.cljc",74,$CLJS.pG,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,$CLJS.oG,$CLJS.U($CLJS.Tj,$CLJS.U(nR,LN))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",
$CLJS.n(zU)?zU.H:null])):null));return $CLJS.n(a)?a:fP}(),zU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof yU?new $CLJS.Gc(function(){return yU},$CLJS.od(qia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([mP,$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,pR,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.JF,"null"],null),null),
$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.xi,"metabase/mbql/schema.cljc",70,$CLJS.Wx,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wx,$CLJS.oG,$CLJS.U($CLJS.Tj,$CLJS.U(nR,LN))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.n(yU)?yU.H:null])):null));return $CLJS.n(a)?a:$CLJS.xi}(),yU],null)]))],null)],null));
$CLJS.X(hQ,iM($CLJS.AM,$CLJS.H(["aggregation",QQ,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.vt,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),uS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),uS],null)],null)])));
$CLJS.X(mR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.vt,"aggregation clause or numeric expression",$CLJS.Ri,function(a){return $CLJS.n(hM($CLJS.AM,a))?$CLJS.AM:dO}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[dO,QQ],null)],null));$CLJS.X(JM,iM($CLJS.lG,$CLJS.H(["field",VS])));var OU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JM],null);$CLJS.X(iP,iM($CLJS.eG,$CLJS.H(["field",VS])));
PU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iP],null);
qka=jM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof OU?new $CLJS.Gc(function(){return OU},$CLJS.od(Ija,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
PO,"metabase/mbql/schema.cljc",15,$CLJS.lG,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lG,$CLJS.oG,$CLJS.U($CLJS.Tj,FR)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.n(OU)?OU.H:null])):null));return $CLJS.n(a)?a:PO}(),OU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof PU?new $CLJS.Gc(function(){return PU},$CLJS.od(zia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,
$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LQ,"metabase/mbql/schema.cljc",16,$CLJS.eG,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,$CLJS.oG,$CLJS.U($CLJS.Tj,FR)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.n(PU)?PU.H:null])):null));return $CLJS.n(a)?a:LQ}(),PU],null)]));
QU=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ws,$CLJS.MM,$CLJS.BN,$CLJS.IF,$CLJS.Xt,$CLJS.AB,$CLJS.HP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,uS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tE,uS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),uS],null)],null);
rka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,QU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,$CLJS.MM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yM,uS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZP,xS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$P,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),xS],null)],null)],null);
ska=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,QU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,$CLJS.BN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iR,xS],null)],null)],null);
RU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,QU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.li],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cO,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ms],null)],null)],null);tka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wR],null);
uka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,RU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,$CLJS.IF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IF,SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eN],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ij,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,$CLJS.$i,$CLJS.li],null)],null)],null)],null);
$CLJS.SU=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.HP,null,$CLJS.Xt,null,$CLJS.Ms,null,$CLJS.AB,null],null),null);vka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ws],null),$CLJS.SU);
$CLJS.X(EQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ri,$CLJS.pj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IF,uka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MM,rka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BN,ska],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dp,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,RU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj,vka],null)],null)],null)],null)],null));
var TU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MQ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,uS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.vt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rO,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,uS],null)],null)],null),wka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,TU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LF,$CLJS.li],null)],null)],null),UU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mQ],null),VU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ri,function(a){return $CLJS.n($CLJS.mE($CLJS.xd,
$CLJS.pQ)(a))?$CLJS.pQ:$CLJS.sE}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,TU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pQ,$CLJS.li],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sE,UU],null)],null),WU=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,uS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kD,vS],null),new $CLJS.P(null,2,5,$CLJS.Q,[gja,uS],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.JO,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,wS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YN,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.mk],null)],null)],null),XU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,ika,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.h(null,1,[$CLJS.vt,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),xka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ws],null),
new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.nF,null,$CLJS.XE,null,$CLJS.gF,null,$CLJS.kF,null],null),null)),YU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uR],null);
$CLJS.X(nO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kP,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),XU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OQ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),VU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WR,$CLJS.XT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),xka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.vt,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,$CLJS.By,$CLJS.jA],null),YU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bF,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),uS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eL,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,zS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XO,new $CLJS.h(null,
1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,WU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.vt,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.mE($CLJS.EA($CLJS.kP,$CLJS.OQ),$CLJS.Te($CLJS.mE($CLJS.kP,$CLJS.OQ)))],null)],null));var yka=$CLJS.Q,ZU;var $U=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nO],null)],null);
if($CLJS.wd($U)&&$CLJS.E.g($CLJS.A($U),$CLJS.Cj)){var aV=$CLJS.y($U);$CLJS.A(aV);var bV=$CLJS.B(aV),zka=$CLJS.xd($CLJS.A(bV))?bV:$CLJS.ee(null,bV),cV=$CLJS.y(zka),Aka=$CLJS.A(cV),Bka=$CLJS.B(cV);ZU=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,$CLJS.R.j(Aka,$CLJS.co,1)],null),Bka)}else ZU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,$U,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.vt,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.X(pN,new $CLJS.P(null,3,5,yka,[$CLJS.Fs,ZU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.vt,"All join aliases must be unique."],null),function(a){return $CLJS.UL($CLJS.lf($CLJS.Wa,$CLJS.hf.g($CLJS.bF,a)))}],null)],null));var Cka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pN],null);
$CLJS.X(uR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ul,new $CLJS.h(null,1,[$CLJS.vt,"Distinct, non-empty sequence of Field clauses"],null),kM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,1,[$CLJS.co,1],null),$CLJS.TS],null))],null));
$CLJS.X(mQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OQ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),VU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kP,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),XU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dG,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,1,[$CLJS.co,1],null),$CLJS.fT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,new $CLJS.h(null,1,
[$CLJS.Qs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,1,[$CLJS.co,1],null),$CLJS.TS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eQ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,uS,$CLJS.xU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),YU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TF,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.XT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rS,new $CLJS.h(null,
1,[$CLJS.Qs,!0],null),yS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UR,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),kM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,1,[$CLJS.co,1],null),qka],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[qQ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[qQ,xS],null),new $CLJS.P(null,2,5,$CLJS.Q,[hia,xS],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JN,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),Cka],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XO,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,WU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.vt,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.im(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OQ,$CLJS.kP],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,
new $CLJS.h(null,1,[$CLJS.vt,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.cG);b=$CLJS.J.g(b,$CLJS.YE);return $CLJS.td($CLJS.lu.g($CLJS.fh(a),$CLJS.fh(b)))}],null)],null));
$CLJS.dV=$CLJS.Ig([$CLJS.HG,$CLJS.HP,jO,VN,YO,iS,EO,$CLJS.hS,$CLJS.Xt,DO,TM,aR,YR,$CLJS.iN,TR,gO,$CLJS.cP,BO,$CLJS.bj,$CLJS.AO,BM,$CLJS.FN,$CLJS.zO,CP,$CLJS.Ms,$CLJS.AB,vO],[new $CLJS.h(null,1,[OO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.HG,null,$CLJS.HP,null,$CLJS.Xt,null,$CLJS.Ms,null,$CLJS.AB,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,$CLJS.HP,OO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.HG,null,$CLJS.HP,null,$CLJS.hS,null,$CLJS.cP,null,$CLJS.bj,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.pj,$CLJS.ck,$CLJS.BE,jR,OO,new $CLJS.ah(null,new $CLJS.h(null,1,[jO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.pj,mS,$CLJS.BE,jR,OO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.HG,null,VN,null,$CLJS.Xt,null,$CLJS.bj,null,BM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.pj,$CLJS.ck,$CLJS.BE,XM,OO,new $CLJS.ah(null,new $CLJS.h(null,1,[YO,null],null),null)],null),new $CLJS.h(null,1,[OO,new $CLJS.ah(null,new $CLJS.h(null,1,[iS,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.pj,
mS,$CLJS.BE,yja,OO,new $CLJS.ah(null,new $CLJS.h(null,1,[EO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,$CLJS.HP,OO,new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hS,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,mS,OO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.HG,null,VN,null,$CLJS.Xt,null,$CLJS.bj,null,BM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.pj,mS,$CLJS.BE,XM,OO,new $CLJS.ah(null,new $CLJS.h(null,1,[DO,null],null),null)],null),new $CLJS.h(null,1,[OO,new $CLJS.ah(null,
new $CLJS.h(null,1,[TM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.pj,$CLJS.ck,$CLJS.BE,jR,OO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.HG,null,iS,null,TM,null,aR,null,BO,null,$CLJS.bj,null,BM,null,$CLJS.AB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.pj,$CLJS.ck,$CLJS.BE,XM,OO,new $CLJS.ah(null,new $CLJS.h(null,1,[YR,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,$CLJS.HP,OO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.hS,null,$CLJS.iN,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.pj,$CLJS.ck,$CLJS.BE,XM,OO,new $CLJS.ah(null,new $CLJS.h(null,1,[TR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.pj,$CLJS.ck,$CLJS.BE,XM,OO,new $CLJS.ah(null,new $CLJS.h(null,1,[gO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,$CLJS.HP,OO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.HG,null,$CLJS.HP,null,$CLJS.hS,null,$CLJS.cP,null,$CLJS.bj,null],null),null)],null),new $CLJS.h(null,1,[OO,new $CLJS.ah(null,new $CLJS.h(null,1,[BO,null],null),null)],null),new $CLJS.h(null,1,[OO,
new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bj,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,$CLJS.HP,OO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.hS,null,$CLJS.AO,null],null),null)],null),new $CLJS.h(null,1,[OO,new $CLJS.ah(null,new $CLJS.h(null,1,[BM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,$CLJS.HP,OO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.hS,null,$CLJS.FN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,$CLJS.HP,OO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.hS,
null,$CLJS.zO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.pj,mS,$CLJS.BE,jR,OO,new $CLJS.ah(null,new $CLJS.h(null,1,[CP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,$CLJS.Ms,OO,new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.HG,null,$CLJS.bj,null,$CLJS.Ms,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,$CLJS.ck,OO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.HG,null,iS,null,TM,null,aR,null,BO,null,$CLJS.bj,null,BM,null,$CLJS.AB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.pj,
mS,$CLJS.BE,XM,OO,new $CLJS.ah(null,new $CLJS.h(null,1,[vO,null],null),null)],null)]);$CLJS.X(wR,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"valid parameter type"],null)],null),$CLJS.Xg($CLJS.dV)));$CLJS.X(eN,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"valid template tag widget type"],null),$CLJS.jA],null),$CLJS.Xg($CLJS.dV)));
$CLJS.X(JQ,iM(Zha,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,uS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,uS],null)],null)],null)])));var eV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JQ],null);$CLJS.X(EM,iM($CLJS.IF,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,$CLJS.TS,eV],null)])));var fV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EM],null);$CLJS.X(zN,iM(yO,$CLJS.H(["target",eV])));
var gV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zN],null),Dka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,$CLJS.TS,jM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof fV?new $CLJS.Gc(function(){return fV},$CLJS.od(Xia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,
1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uQ,"metabase/mbql/schema.cljc",21,$CLJS.IF,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IF,$CLJS.zJ,$CLJS.U($CLJS.Tj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,LN,NO],null))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.n(fV)?fV.H:null])):null));return $CLJS.n(a)?a:uQ}(),fV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=oP.h($CLJS.pd("undefined"!==typeof $CLJS.lM&&
"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof gV?new $CLJS.Gc(function(){return gV},$CLJS.od(mja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.tk,$CLJS.vi,oP,$CLJS.Wi,$CLJS.qj,$CLJS.Ck,kN,$CLJS.ui,$CLJS.ok,$CLJS.sk],[$CLJS.od(VQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nS,"metabase/mbql/schema.cljc",20,yO,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[yO,
$CLJS.zJ,$CLJS.U($CLJS.Tj,NO)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.n(gV)?gV.H:null])):null));return $CLJS.n(a)?a:nS}(),gV],null)]))],null),eM=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lN],null);
$CLJS.X(lN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.vt,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.mE($CLJS.EA($CLJS.pQ,$CLJS.LF),$CLJS.Te($CLJS.mE($CLJS.pQ,$CLJS.LF)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.vt,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.pQ);var d=$CLJS.J.g(c,$CLJS.LF);c=$CLJS.J.g(c,$CLJS.pj);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$P,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.vt,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iL],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.dL],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,$CLJS.LF,$CLJS.pQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pQ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),wka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LF,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),UU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AN,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.P(null,
8,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj,tka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),uS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zJ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),Dka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gj,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.li],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),uS],null),new $CLJS.P(null,3,5,$CLJS.Q,[eia,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),
uS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.li],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gL,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[Xja,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),HS],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Wia,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[uP,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),yS],null),new $CLJS.P(null,3,5,$CLJS.Q,[dN,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),yS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.vt,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,uP);b=$CLJS.J.g(b,dN);return $CLJS.Va($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Mia,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[Hja,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ms],null),new $CLJS.P(null,3,5,$CLJS.Q,[aia,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ms],null),new $CLJS.P(null,3,5,$CLJS.Q,[Kha,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ms],null),new $CLJS.P(null,3,5,$CLJS.Q,[gia,new $CLJS.h(null,1,[$CLJS.Qs,
!0],null),$CLJS.Ms],null),new $CLJS.P(null,3,5,$CLJS.Q,[zja,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Ms],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Mha,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Ms],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Nja,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Ms],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ly,new $CLJS.h(null,
1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vN,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.ws,lja,ija,$CLJS.rO,kia,gka,nja,Cia,Yia,uia,bia,yia,wja,Kia,Wha,lia,fka,cka],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Cja,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,xS],null)],null),new $CLJS.P(null,3,5,
$CLJS.Q,[Hia,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,xS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iR,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,hka],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Yja,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,uS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Jia,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,xS],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[Wja,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,$CLJS.li,$CLJS.li],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Dia,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,xS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uO,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Ds,$CLJS.li,$CLJS.li],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SK,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.li],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.vt,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Te($CLJS.XO)],null)],null));var hV=$CLJS.dM(eM);
(function(){var a=Fha();return function(b){if($CLJS.n(hV.h?hV.h(b):hV.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.aM(b);throw $CLJS.hi($CLJS.oE("Invalid query: {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.Hy,c,Ria,b],null));}})();