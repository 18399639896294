var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var yW,$ka,ala,GW,bla,cla,ela,CW,fla,dla;yW=function(a){return a+1};$CLJS.zW=function(a){if("string"===typeof a){var b=$CLJS.nh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.nm(a));};
$ka=function(a){var b=new $CLJS.Ea,c=$CLJS.Cu;$CLJS.Cu=new $CLJS.sc(b);try{var d=$CLJS.Cu,e=$CLJS.Va($CLJS.Kv(d)),f=$CLJS.Cu;$CLJS.Cu=e?$CLJS.Bv(d):d;try{d=$CLJS.Mv;$CLJS.Mv=!0;try{$CLJS.Ov(a)}finally{$CLJS.Mv=d}$CLJS.E.g(0,$CLJS.Ju($CLJS.Cu,$CLJS.Lu))||$CLJS.ac($CLJS.Cu,"\n");$CLJS.Iu()}finally{$CLJS.Cu=f}$CLJS.uh($CLJS.p.h(b))}finally{$CLJS.Cu=c}};$CLJS.AW=function(a,b){return $CLJS.Se.j($CLJS.fa,a,b)};
$CLJS.BW=function(a){var b=$CLJS.yv;$CLJS.yv=120;try{var c=new $CLJS.Ea,d=$CLJS.Qa,e=$CLJS.Ra;$CLJS.Qa=!0;$CLJS.Ra=function(f){return c.append(f)};try{$ka(a)}finally{$CLJS.Ra=e,$CLJS.Qa=d}return $CLJS.p.h(c)}finally{$CLJS.yv=b}};
ala=function(a){a=$CLJS.O(a);var b=$CLJS.J.j(a,$CLJS.KV,$CLJS.Td),c=$CLJS.J.j(a,$CLJS.HV,function(f,k){return[$CLJS.p.h(f),"_",$CLJS.p.h(k)].join("")}),d=$CLJS.Xe($CLJS.N),e=$CLJS.Xe($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var z=new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),C=$CLJS.q(d);return C.h?C.h(z):C.call(null,z)}();if($CLJS.n(v))return v;var x=$CLJS.J.g($CLJS.Oh.v(e,$CLJS.Wk,u,$CLJS.ZD(yW,0)),u);if($CLJS.E.g(x,1))return $CLJS.Oh.v(d,$CLJS.R,
new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),t),t;t=function(){var z=t,C=$CLJS.p.h(x);return c.g?c.g(z,C):c.call(null,z,C)}()}}function k(m){var t=l,u=t.g;null==CW&&(CW=$CLJS.Xe(0));var v=$CLJS.Fh.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.Oh.g(CW,yW))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return k.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=k;l.g=f;return l}()};
$CLJS.DW=function(a){return $CLJS.zd(a)&&1<$CLJS.D(a)&&$CLJS.A(a)instanceof $CLJS.M&&$CLJS.xd($CLJS.hd(a))&&$CLJS.Hd($CLJS.hd(a),$CLJS.FE)};$CLJS.EW=function(a){return $CLJS.DW(a)?$CLJS.xE.h($CLJS.ME(a)):null};$CLJS.FW=function(a,b){return $CLJS.NE($CLJS.DW(a)?a:new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gj,new $CLJS.h(null,2,[$CLJS.FE,$CLJS.p.h($CLJS.PE()),$CLJS.Hi,$CLJS.TE(a)],null),a],null),$CLJS.R,$CLJS.H([$CLJS.xE,b]))};
GW=function(a,b,c){var d=$CLJS.Hd(a,b)?$CLJS.Wk.j(a,b,function(e){return $CLJS.zd(e)&&$CLJS.E.g($CLJS.A(e),$CLJS.Fs)?$CLJS.Df($CLJS.bf(1,e)):new $CLJS.P(null,1,5,$CLJS.Q,[e],null)}):a;return $CLJS.Hd(a,b)?$CLJS.FV(d,$CLJS.Ee([b,c])):d};bla=function(a){var b=$CLJS.im(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kP,$CLJS.OQ],null));b=HW.h?HW.h(b):HW.call(null,b);return $CLJS.vW($CLJS.R.l(GW($CLJS.Lk.l(a,$CLJS.kP,$CLJS.H([$CLJS.OQ])),$CLJS.WR,$CLJS.xL),$CLJS.cu,$CLJS.pL,$CLJS.H([$CLJS.uL,b])))};
cla=function(a){return $CLJS.Vk.g(bla,a)};ela=function(a){return $CLJS.n(a)?$CLJS.R.j($CLJS.Wk.j($CLJS.Ql(a)?new $CLJS.h(null,1,[$CLJS.IW,a],null):a,$CLJS.IW,function(b){return $CLJS.Vk.g(function(c){return $CLJS.R.j($CLJS.GA(c,$CLJS.gB),$CLJS.cu,$CLJS.TK)},b)}),$CLJS.cu,dla):null};
$CLJS.JW=function(a){if($CLJS.E.g($CLJS.cu.h(a),$CLJS.nW))return a;var b=$CLJS.pj.h(a);b=b instanceof $CLJS.M?b.T:null;switch(b){case "native":return $CLJS.kl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.cu,$CLJS.nW,$CLJS.uL,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.cu,$CLJS.hW],null),$CLJS.FV($CLJS.pQ.h(a),new $CLJS.h(null,1,[$CLJS.LF,$CLJS.pQ],null))]))],null)],null),$CLJS.Lk.l(a,$CLJS.pj,$CLJS.H([$CLJS.pQ]))]));case "query":return $CLJS.kl.l($CLJS.H([new $CLJS.h(null,
2,[$CLJS.cu,$CLJS.nW,$CLJS.uL,HW($CLJS.LF.h(a))],null),$CLJS.Lk.l(a,$CLJS.pj,$CLJS.H([$CLJS.LF]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.KW=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.uL);var c=0>b?$CLJS.D(a)+b:b;if(c>=$CLJS.D(a)||0>c)throw $CLJS.hi($CLJS.oE("Stage {0} does not exist",$CLJS.H([b])),new $CLJS.h(null,1,[fla,$CLJS.D(a)],null));return c};
$CLJS.LW=function(a,b){a=$CLJS.JW(a);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.uL);return $CLJS.J.g($CLJS.Df(c),$CLJS.KW(a,b))};$CLJS.MW=function(a){return"string"===typeof a&&(a=$CLJS.oh(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.I(a,0,null),a=$CLJS.I(a,1,null),$CLJS.zW(a)):null};$CLJS.OW=function(){return $CLJS.Tk.g(NW,ala($CLJS.H([$CLJS.KV,$CLJS.cB,$CLJS.HV,function(a,b){return NW.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};CW=null;
$CLJS.PW=new $CLJS.M("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);$CLJS.QW=new $CLJS.M("metabase.lib.util","cannot-remove-final-join-condition","metabase.lib.util/cannot-remove-final-join-condition",-470911224);$CLJS.RW=new $CLJS.M(null,"join","join",-758861890);$CLJS.SW=new $CLJS.M(null,"stage","stage",1843544772);fla=new $CLJS.M(null,"num-stages","num-stages",1426797535);$CLJS.IW=new $CLJS.M(null,"columns","columns",1998437288);
$CLJS.TW=new $CLJS.M("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);$CLJS.UW=new $CLJS.M(null,"table","table",-564943036);dla=new $CLJS.M("metadata","results","metadata/results",330329298);$CLJS.VW=new $CLJS.M("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);var HW,NW;
HW=function HW(a){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.OQ),d=$CLJS.J.g(a,$CLJS.XO);c=$CLJS.n(c)?HW.h?HW.h(c):HW.call(null,c):$CLJS.Cf;d=ela(d);var e=$CLJS.D(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.bM(c,new $CLJS.P(null,2,5,$CLJS.Q,[e,$CLJS.tV],null),d):c;d=$CLJS.n($CLJS.pQ.h(a))?$CLJS.hW:$CLJS.aW;a=$CLJS.kl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.cu,d],null),$CLJS.Lk.l(a,$CLJS.OQ,$CLJS.H([$CLJS.XO]))]));a=$CLJS.y($CLJS.JN.h(a))?$CLJS.Wk.j(a,$CLJS.JN,cla):a;a=GW(a,$CLJS.TF,$CLJS.oW);return $CLJS.be.g(c,a)};
$CLJS.WW=function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e,f){c=$CLJS.JW(c);c=$CLJS.O(c);var k=$CLJS.J.g(c,$CLJS.uL);d=$CLJS.KW(c,d);e=$CLJS.Se.N($CLJS.Wk,$CLJS.Df(k),d,e,f);return $CLJS.R.j(c,$CLJS.uL,e)}a.A=3;a.B=function(c){var d=$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.B(c);var f=$CLJS.A(c);c=$CLJS.Lc(c);return b(d,e,f,c)};a.l=
b;return a}();
NW=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.tW.str(d,0)>>>0).toString(16);;)if(8>$CLJS.D(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.XW=RegExp(" id$","i");