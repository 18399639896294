var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var vfa,wfa,xfa,yfa,zfa,Afa,Bfa,Cfa,Dfa;vfa=function(a,b){a.sort(b||$CLJS.Da)};wfa=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Da;vfa(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.ZG=function(a){return $CLJS.E.g(a,$CLJS.Jd)?$CLJS.Jd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.$G=function(a,b){if($CLJS.y(b)){var c=$CLJS.we.h?$CLJS.we.h(b):$CLJS.we.call(null,b);wfa(c,$CLJS.ZG(a));return $CLJS.od($CLJS.y(c),$CLJS.pd(b))}return $CLJS.Kc};$CLJS.aH=new $CLJS.M(null,"second","second",-444702010);$CLJS.bH=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);
$CLJS.cH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.dH=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.eH=new $CLJS.M(null,"year-of-era","year-of-era",682445876);xfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
yfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.fH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.gH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);
$CLJS.hH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);zfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);Afa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);
Bfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);Cfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.iH=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.jH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);
$CLJS.kH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);Dfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var lH=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Ii,$CLJS.Jj,$CLJS.Hj,$CLJS.qi,$CLJS.qk,$CLJS.Ci,$CLJS.ji,$CLJS.eH],null),mH=$CLJS.fh(lH),Efa,Ffa,Gfa;$CLJS.X(Bfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"Valid date extraction unit"],null)],null),mH));var nH=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.du,$CLJS.rj,$CLJS.Aj,$CLJS.pi,$CLJS.ji],null),oH=$CLJS.fh(nH);
$CLJS.X(Afa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"Valid date truncation unit"],null)],null),oH));$CLJS.pH=$CLJS.eg.j($CLJS.Cf,$CLJS.nl.o(),$CLJS.gf.g(nH,lH));Efa=$CLJS.fh($CLJS.pH);$CLJS.X($CLJS.hH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"Valid date bucketing unit"],null)],null),Efa));var qH=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bH,$CLJS.lj,$CLJS.wi],null),rH=$CLJS.fh(qH);
$CLJS.X(zfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"Valid time extraction unit"],null)],null),rH));var sH=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dH,$CLJS.aH,$CLJS.Xi,$CLJS.uk],null),tH=$CLJS.fh(sH);$CLJS.X(Dfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"Valid time truncation unit"],null)],null),tH));$CLJS.uH=$CLJS.eg.j($CLJS.Cf,$CLJS.nl.o(),$CLJS.gf.g(sH,qH));Ffa=$CLJS.fh($CLJS.uH);
$CLJS.X(yfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"Valid time bucketing unit"],null)],null),Ffa));$CLJS.vH=$CLJS.eg.j($CLJS.Cf,$CLJS.nl.o(),$CLJS.gf.l(sH,nH,$CLJS.H([qH,lH])));Gfa=$CLJS.fh($CLJS.vH);$CLJS.X($CLJS.cH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"Valid datetime bucketing unit"],null)],null),$CLJS.vH));var Hfa=$CLJS.be.g(Gfa,$CLJS.ci);
$CLJS.X($CLJS.jH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"Valid temporal bucketing unit"],null)],null),Hfa));$CLJS.wH=$CLJS.eu.g(oH,tH);$CLJS.X($CLJS.fH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"Valid datetime truncation unit"],null)],null),$CLJS.wH));$CLJS.xH=$CLJS.eu.g(mH,rH);$CLJS.X(xfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"Valid datetime extraction unit"],null)],null),$CLJS.xH));
var yH=$CLJS.be.g(oH,$CLJS.ji);$CLJS.X($CLJS.kH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"Valid date interval unit"],null)],null),yH));$CLJS.X($CLJS.gH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"Valid time interval unit"],null)],null),tH));var Ifa=$CLJS.eu.g(yH,tH);$CLJS.X($CLJS.lE,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.vt,"Valid datetime interval unit"],null)],null),Ifa));
$CLJS.X(Cfa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,$CLJS.iH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.si,$CLJS.jH],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ms],null)],null));