var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./malli.registry.js");require("./malli.util.js");
'use strict';var $D,aE,bE,Wea,cE,eE,fE;
$CLJS.ZD=function(a,b){return function(){function c(l,m,t){l=null==l?b:l;return a.j?a.j(l,m,t):a.call(null,l,m,t)}function d(l,m){l=null==l?b:l;return a.g?a.g(l,m):a.call(null,l,m)}function e(l){l=null==l?b:l;return a.h?a.h(l):a.call(null,l)}var f=null,k=function(){function l(t,u,v,x){var z=null;if(3<arguments.length){z=0;for(var C=Array(arguments.length-3);z<C.length;)C[z]=arguments[z+3],++z;z=new $CLJS.w(C,0,null)}return m.call(this,t,u,v,z)}function m(t,u,v,x){return $CLJS.Se.N(a,null==t?b:t,u,
v,x)}l.A=3;l.B=function(t){var u=$CLJS.A(t);t=$CLJS.B(t);var v=$CLJS.A(t);t=$CLJS.B(t);var x=$CLJS.A(t);t=$CLJS.Lc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.w(x,0,null)}return k.l(l,m,t,v)}throw Error("Invalid arity: "+arguments.length);
};f.A=3;f.B=k.B;f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};$D=function(a,b){this.db=a;this.Ah=b;this.C=393216;this.I=0};aE=function(a){for(var b=null;;){var c;a=$CLJS.Co.g(a,b);a=c=$CLJS.Vn(a)?$CLJS.Pn(a):a;if(!$CLJS.Vn(c))return a}};bE=function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return!($CLJS.xd(a)&&!0===$CLJS.Qs.h(a))};
Wea=function(a,b,c){var d=aE($CLJS.Co.g(a,c));return $CLJS.Jq($CLJS.Gn(d),$CLJS.Dn(d),function(){var e=$CLJS.Fn(d);return b.h?b.h(e):b.call(null,e)}(),function(){var e=$CLJS.En($CLJS.Co.g(d,null));return $CLJS.n(e)?e:c}())};cE=function(a){return function(b,c,d){var e=$CLJS.y(c);b=$CLJS.A(e);e=$CLJS.B(e);c=$CLJS.Vk.g(function(f){return $CLJS.Co.g(f,d)},c);return new $CLJS.P(null,3,5,$CLJS.Q,[c,$CLJS.Vk.g($CLJS.jt,c),$CLJS.fb(function(f,k){return a.j?a.j(f,k,d):a.call(null,f,k,d)},b,e)],null)}};
$CLJS.dE=function(a,b,c,d,e,f,k,l,m,t,u,v,x,z,C,G,K,S,V){this.form=a;this.options=b;this.Ri=c;this.forms=d;this.U=e;this.Yd=f;this.za=k;this.children=l;this.min=m;this.Ne=t;this.Eb=u;this.Rb=v;this.parent=x;this.Ve=z;this.re=C;this.type=G;this.cache=K;this.max=S;this.Zh=V;this.C=393216;this.I=0};eE=function(a,b,c,d,e,f,k,l,m){this.Ve=a;this.re=b;this.type=c;this.min=d;this.max=e;this.Yd=f;this.Eb=k;this.Rb=l;this.Ne=m;this.C=393216;this.I=0};
fE=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.pj),d=$CLJS.J.g(b,$CLJS.co),e=$CLJS.J.g(b,$CLJS.nk),f=$CLJS.J.g(b,$CLJS.yt),k=$CLJS.J.g(b,$CLJS.kq),l=$CLJS.J.g(b,$CLJS.Ui);return new eE(a,b,c,d,e,f,k,l,new $CLJS.h(null,1,[$CLJS.pj,$CLJS.Qp],null))};$CLJS.X=function(a,b){$CLJS.Oh.v(gE,$CLJS.R,a,b);$CLJS.Ye($CLJS.hE,$CLJS.N);return null};$D.prototype.P=function(a,b){return new $D(this.db,b)};$D.prototype.O=function(){return this.Ah};$D.prototype.sd=$CLJS.Bc;
$D.prototype.cd=function(a,b){return $CLJS.rn($CLJS.tn($CLJS.q(this.db)),b)};var iE=function iE(a){switch(arguments.length){case 2:return iE.g(arguments[0],arguments[1]);case 3:return iE.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};iE.g=function(a,b){return iE.j(a,b,null)};
iE.j=function(a,b,c){function d(v,x){return $CLJS.E.g($CLJS.mk,v)?new $CLJS.P(null,2,5,$CLJS.Q,[null,x],null):$CLJS.gf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Yq.h(x)],null),$CLJS.Zq.h(x))}function e(v,x){return $CLJS.n($CLJS.n(v)?x:v)?$CLJS.kl.l($CLJS.H([v,x])):$CLJS.n(v)?v:x}a=$CLJS.n(a)?aE($CLJS.Co.g(a,c)):null;b=$CLJS.n(b)?aE($CLJS.Co.g(b,c)):null;var f=$CLJS.n(a)?$CLJS.jo.h(a):null,k=$CLJS.n(b)?$CLJS.jo.h(b):null,l=$CLJS.O(c),m=$CLJS.J.j(l,$CLJS.tt,function(v,x){return x}),t=$CLJS.J.j(l,$CLJS.wt,
function(v,x){return x});if(null==a)return b;if(null==b)return a;if($CLJS.Va(function(){var v=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.Fs,null,$CLJS.mk,null],null),null);v=v.h?v.h(f):v.call(null,f);return $CLJS.n(v)?(v=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.Fs,null,$CLJS.mk,null],null),null),v.h?v.h(k):v.call(null,k)):v}()))return m.j?m.j(a,b,c):m.call(null,a,b,c);if($CLJS.E.g(f,$CLJS.mk)&&$CLJS.E.g(k,$CLJS.mk)){l=e($CLJS.Dn(a),$CLJS.Dn(b));var u=$CLJS.Xe($CLJS.bh);a=$CLJS.fb(function(v,x){var z=
$CLJS.I(x,0,null);if($CLJS.n(function(){var C=$CLJS.q(u);return C.h?C.h(z):C.call(null,z)}()))return $CLJS.fb(function(C,G){var K=$CLJS.I(G,0,null),S=$CLJS.be.g;if($CLJS.E.g(K,z)){K=$CLJS.I(G,0,null);var V=$CLJS.I(G,1,null),Z=$CLJS.I(G,2,null);$CLJS.I(x,0,null);var ha=$CLJS.I(x,1,null),ra=$CLJS.I(x,2,null);G=bE(G);var Na=bE(x);G=t.g?t.g(G,Na):t.call(null,G,Na);V=$CLJS.kl.l($CLJS.H([V,ha]));ra=new $CLJS.P(null,3,5,$CLJS.Q,[K,$CLJS.R.j(V,$CLJS.Qs,$CLJS.Va(G)),iE.j?iE.j(Z,ra,c):iE.call(null,Z,ra,c)],
null);K=$CLJS.I(ra,0,null);Z=$CLJS.I(ra,1,null);ra=$CLJS.I(ra,2,null);K=$CLJS.Va(ra)?new $CLJS.P(null,2,5,$CLJS.Q,[K,Z],null):$CLJS.n($CLJS.n(Z)?!1===$CLJS.Qs.h(Z)&&$CLJS.E.g(1,$CLJS.D(Z)):Z)?new $CLJS.P(null,2,5,$CLJS.Q,[K,ra],null):$CLJS.Va($CLJS.y(Z))?new $CLJS.P(null,2,5,$CLJS.Q,[K,ra],null):!1===$CLJS.Qs.h(Z)?new $CLJS.P(null,3,5,$CLJS.Q,[K,$CLJS.Lk.g(Z,$CLJS.Qs),ra],null):new $CLJS.P(null,3,5,$CLJS.Q,[K,Z,ra],null)}else K=G;return S.call($CLJS.be,C,K)},$CLJS.Cf,v);$CLJS.Oh.j(u,$CLJS.be,z);return $CLJS.be.g(v,
x)},$CLJS.Cf,$CLJS.eg.g($CLJS.Fn(a),$CLJS.Fn(b)));return $CLJS.Jq($CLJS.mk,l,a,c)}return function(v,x){var z=$CLJS.y(v);v=$CLJS.A(z);var C=$CLJS.B(z);z=$CLJS.A(C);C=$CLJS.B(C);var G=$CLJS.y(x);x=$CLJS.A(G);var K=$CLJS.B(G);G=$CLJS.A(K);K=$CLJS.B(K);return $CLJS.Jq($CLJS.Fs,e(v,x),$CLJS.gf.l(new $CLJS.P(null,1,5,$CLJS.Q,[iE.j(z,G,c)],null),C,$CLJS.H([K])),c)}(d(f,a),d(k,b))};iE.A=3;
var jE=function jE(a){switch(arguments.length){case 2:return jE.g(arguments[0],arguments[1]);case 3:return jE.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};jE.g=function(a,b){return jE.j(a,b,null)};
jE.j=function(a,b,c){return iE.j(a,b,$CLJS.Wk.j($CLJS.Wk.j(c,$CLJS.tt,$CLJS.ZD($CLJS.Td,function(d,e,f){var k=$CLJS.E.g($CLJS.jt.g(d,null),$CLJS.jt.g(e,null));return $CLJS.n(k)?d:$CLJS.Co.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,d,e],null),f)})),$CLJS.wt,$CLJS.ZD($CLJS.Td,function(d,e){return $CLJS.n(d)?e:d})))};jE.A=3;
var kE=function kE(a){switch(arguments.length){case 2:return kE.g(arguments[0],arguments[1]);case 3:return kE.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};kE.g=function(a,b){return kE.j(a,b,null)};kE.j=function(a,b,c){var d=$CLJS.fh(b);return Wea(a,function(e){return $CLJS.lf(function(f){f=$CLJS.I(f,0,null);return d.h?d.h(f):d.call(null,f)},e)},c)};kE.A=3;$CLJS.g=$CLJS.dE.prototype;
$CLJS.g.P=function(a,b){return new $CLJS.dE(this.form,this.options,this.Ri,this.forms,this.U,this.Yd,this.za,this.children,this.min,this.Ne,this.Eb,this.Rb,this.parent,this.Ve,this.re,this.type,this.cache,this.max,b)};$CLJS.g.O=function(){return this.Zh};$CLJS.g.Ya=$CLJS.Bc;$CLJS.g.ya=function(){return $CLJS.An(this.za)};$CLJS.g.La=function(){return this.options};$CLJS.g.Ta=function(a,b,c,d){return $CLJS.lp(this,new $CLJS.P(null,1,5,$CLJS.Q,[this.za],null),b,c,d)};$CLJS.g.$a=function(){return this.U};
$CLJS.g.ua=function(){return this.children};$CLJS.g.Sa=function(){return $CLJS.q(this.form)};$CLJS.g.Ra=function(a,b){return $CLJS.Bn(this.za,b)};$CLJS.g.Za=function(){return this.parent};$CLJS.g.Wa=$CLJS.Bc;$CLJS.g.ia=$CLJS.ua(5);$CLJS.g.je=$CLJS.Bc;$CLJS.g.Ld=function(){return this.za};$CLJS.g=eE.prototype;$CLJS.g.P=function(a,b){return new eE(this.Ve,this.re,this.type,this.min,this.max,this.Yd,this.Eb,this.Rb,b)};$CLJS.g.O=function(){return this.Ne};$CLJS.g.Xa=$CLJS.Bc;$CLJS.g.Pa=function(){return this.type};
$CLJS.g.Qa=function(){return this.Eb};$CLJS.g.Oa=function(a,b,c,d){var e=this;$CLJS.eo(e.type,b,c,e.min,e.max);a=function(){var m=$CLJS.Df(c);return e.Rb.j?e.Rb.j(b,m,d):e.Rb.call(null,b,m,d)}();var f=$CLJS.I(a,0,null),k=$CLJS.I(a,1,null),l=$CLJS.I(a,2,null);return new $CLJS.dE(new $CLJS.Jh(function(){return $CLJS.Io(e.type,b,k,d)}),d,a,k,b,e.Yd,l,f,e.min,e.Ne,e.Eb,e.Rb,this,e.Ve,e.re,e.type,$CLJS.Ho(),e.max,new $CLJS.h(null,1,[$CLJS.pj,$CLJS.oq],null))};
$CLJS.lE=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);var gE;$CLJS.hE=$CLJS.Xe($CLJS.N);
gE=$CLJS.Xe($CLJS.kl.l($CLJS.H([$CLJS.Os(),new $CLJS.h(null,3,[$CLJS.ut,fE(new $CLJS.h(null,2,[$CLJS.pj,$CLJS.ut,$CLJS.Ui,cE(iE)],null)),$CLJS.st,fE(new $CLJS.h(null,2,[$CLJS.pj,$CLJS.st,$CLJS.Ui,cE(jE)],null)),$CLJS.zt,fE(new $CLJS.h(null,5,[$CLJS.pj,$CLJS.zt,$CLJS.yt,1,$CLJS.co,2,$CLJS.nk,2,$CLJS.Ui,function(a,b,c){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wk.j(b,0,function(d){return $CLJS.Co.g(d,c)}),$CLJS.Wk.j(b,0,function(d){return $CLJS.jt.g(d,c)}),$CLJS.Se.g(kE,$CLJS.be.g(b,c))],null)}],null))],
null)])));$CLJS.Ye($CLJS.Al,$CLJS.tn(new $D(gE,$CLJS.N)));